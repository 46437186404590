import React, { useEffect, useState } from "react";
import { RecurrenceOptionsSelectProps } from "./types";
import { Box, Button, ButtonBase, CircularProgress, FilledInput, InputProps, MenuItem, Select, SelectProps, Tooltip, Typography, useTheme } from "@mui/material";
import { plural, t } from "@lingui/macro";
import { resolveRecurrenceTypeLabel, resolveWeekdayIndex, resolveWeekdayLabel } from "utils";
import { DailyRecurrenceOptions, RecurrenceType, WeeklyRecurrenceOptions } from "types";
import { formatInTimeZone } from "date-fns-tz";
import { BlockRounded, CheckRounded, TodayOutlined } from "@mui/icons-material";
import { DayPickerPopover } from "../popover";
import { resolveDefaultDailyRecurrenceOptions, resolveDefaultMonthlyRecurrenceOptions, resolveDefaultWeeklyRecurrenceOptions } from "./utils";
import { RecurrenceConflictsDialog } from "./recurrence-conflicts-dialog";
import { endOfMonth, getDate, getISODay, isSameMinute, set } from "date-fns";
import { RRule } from "rrule";
import { MonthlyRecurrenceOptions } from "types/monthly-recurrence-options.type";
import { isUndefined, omitBy } from "lodash";
import { Collapse } from "@material-ui/core";

const types: Array<"none" | RecurrenceType> = ["none", "daily", "weekly", "monthly"];

const DailyOptions: React.FC<{
  options: DailyRecurrenceOptions;
  timeZone: string;
  onChange: (options: DailyRecurrenceOptions) => void;
  maxDate?: Date;
}> = (props) => {
  const { timeZone, options, maxDate, onChange } = props;
  const { start, end, interval } = options;
  const { palette } = useTheme();
  const [endAnchorEl, setEndAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleIntervalChange: InputProps["onChange"] = (event) => {
    const interval = Number.parseInt(event.target.value, 10);

    if (!isNaN(interval) && interval > 0 && interval < 7) {
      onChange?.({ ...options, interval });
    }
  };

  const handleEndChange = (end: Date) => {
    onChange?.({
      ...options,
      end: set(end, { hours: start.getHours(), minutes: start.getMinutes(), seconds: start.getSeconds(), milliseconds: start.getMilliseconds() }),
    });
    setEndAnchorEl(null);
  };

  return (
    <>
      <Box alignItems="center" display="flex" flexBasis="50%" flexGrow={0} flexShrink={0} justifyContent="flex-end">
        <Typography color={palette.grey[400]} fontSize={14} mr={1}>{t`every day(s)`}</Typography>
        <FilledInput onChange={handleIntervalChange} sx={{ width: 64 }} type="number" value={interval} />
      </Box>
      <Box alignItems="center" display="flex" flexGrow={0} flexShrink={0} justifyContent="flex-end" pt={1} width="50%">
        <Typography color={palette.grey[400]} fontSize={14} mr={1}>{t`Start`}</Typography>
        <Box alignItems="center" bgcolor={palette.grey[100]} borderRadius={2} display="flex" height={35} minWidth={140} paddingX={1}>
          <Typography fontSize={14} lineHeight={1}>{formatInTimeZone(start, timeZone, "d LLL, yyyy")}</Typography>
        </Box>
      </Box>
      <Box alignItems="center" display="flex" flexGrow={0} flexShrink={0} justifyContent="flex-end" pt={1} width="50%">
        <Typography color={palette.grey[400]} fontSize={14} mr={1}>{t`End`}</Typography>
        <Button
          onClick={(event) => setEndAnchorEl(event.currentTarget)}
          sx={{ alignItems:"center", bgcolor: palette.grey[100], display:"flex", height: 35, justifyContent:"space-between", width: 140 }}
        >
          <Typography color={palette.grey[900]} fontSize={14} lineHeight={1}>{formatInTimeZone(end, timeZone, "d LLL, yyyy")}</Typography>
          <TodayOutlined color="primary" />
        </Button>
      </Box>
      <DayPickerPopover
        anchorEl={endAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        maxDate={maxDate}
        minDate={start}
        onChange={handleEndChange}
        onClose={() => setEndAnchorEl(null)}
        open={!!endAnchorEl}
        sx={{ mt: -1 }}
        timeZone={timeZone}
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
        value={end}
      />
    </>
  );
};

const weekdaysOptions = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU];

const WeeklyOptions: React.FC<{
  options: WeeklyRecurrenceOptions;
  timeZone: string;
  onChange: (options: WeeklyRecurrenceOptions) => void;
  maxDate?: Date;
}> = (props) => {
  const { timeZone, options, maxDate, onChange } = props;
  const { start, end, interval, weekdays } = options;
  const { palette, background } = useTheme();
  const [endAnchorEl, setEndAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleIntervalChange: InputProps["onChange"] = (event) => {
    const interval = Number.parseInt(event.target.value, 10);

    if (!isNaN(interval) && interval > 0 && interval < 7) {
      onChange?.({ ...options, interval });
    }
  };

  const handleEndChange = (end: Date) => {
    onChange?.({
      ...options,
      end: set(end, { hours: start.getHours(), minutes: start.getMinutes(), seconds: start.getSeconds(), milliseconds: start.getMilliseconds() }),
    });
    setEndAnchorEl(null);
  };

  const handleWeekdaysChange = (value: number) => {
    if (weekdays.includes(value)) {
      if (weekdays.length > 1) {
        onChange?.({
          ...options,
          weekdays: weekdays.filter((weekday) => weekday !== value),
        });
      }
    } else {
      onChange?.({
        ...options,
        weekdays: [...weekdays, value],
      });
    }
  };

  return (
    <>
      <Box alignItems="center" display="flex" flexBasis="50%" flexGrow={0} flexShrink={0} justifyContent="flex-end">
        <Typography color={palette.grey[400]} fontSize={14} mr={1}>{t`every weeks(s)`}</Typography>
        <FilledInput onChange={handleIntervalChange} sx={{ width: 64 }} type="number" value={interval} />
      </Box>
      <Box alignItems="center" display="flex" flexGrow={0} flexShrink={0} justifyContent="flex-end" pt={1} width="100%">
        <Typography color={palette.grey[400]} fontSize={14} mr={1}>{t`Days`}</Typography>
        <Box alignItems="center" display="flex" gap={1} minWidth={350}>
          {weekdaysOptions.map((option) => {
            const isSelected = weekdays.includes(option.weekday);

            return (
              <ButtonBase
                key={option.toString()}
                onClick={() => handleWeekdaysChange(option.weekday)}
                sx={{
                  borderRadius: 2,
                  bgcolor: isSelected ? background.blue : palette.grey[100],
                  py: 1,
                  flex: 1,
                  color: isSelected ? palette.primary.main : undefined,
                }}
              >
                {resolveWeekdayLabel(option)}
              </ButtonBase>
            );
          })}
        </Box>
      </Box>
      <Box alignItems="center" display="flex" flexGrow={0} flexShrink={0} justifyContent="flex-end" pt={1} width="50%">
        <Typography color={palette.grey[400]} fontSize={14} mr={1}>{t`Start`}</Typography>
        <Box alignItems="center" bgcolor={palette.grey[100]} borderRadius={2} display="flex" height={35} minWidth={140} paddingX={1}>
          <Typography fontSize={14} lineHeight={1}>{formatInTimeZone(start, timeZone, "d LLL, yyyy")}</Typography>
        </Box>
      </Box>
      <Box alignItems="center" display="flex" flexGrow={0} flexShrink={0} justifyContent="flex-end" pt={1} width="50%">
        <Typography color={palette.grey[400]} fontSize={14} mr={1}>{t`End`}</Typography>
        <Button
          onClick={(event) => setEndAnchorEl(event.currentTarget)}
          sx={{ alignItems:"center", bgcolor: palette.grey[100], display:"flex", height: 35, justifyContent:"space-between", width: 140 }}
        >
          <Typography color={palette.grey[900]} fontSize={14} lineHeight={1}>{formatInTimeZone(end, timeZone, "d LLL, yyyy")}</Typography>
          <TodayOutlined color="primary" />
        </Button>
      </Box>
      <DayPickerPopover
        anchorEl={endAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        maxDate={maxDate}
        minDate={start}
        onChange={handleEndChange}
        onClose={() => setEndAnchorEl(null)}
        open={!!endAnchorEl}
        sx={{ mt: -1 }}
        timeZone={timeZone}
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
        value={end}
      />
    </>
  );
};

const MonthlyOptions: React.FC<{
  options: MonthlyRecurrenceOptions;
  timeZone: string;
  onChange: (options: MonthlyRecurrenceOptions) => void;
  maxDate?: Date;
}> = (props) => {
  const { timeZone, options, maxDate, onChange } = props;
  const { start, end, interval } = options;
  const { palette } = useTheme();
  const [endAnchorEl, setEndAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isDayOfMonth = "dayOfMonth" in options;

  const handleIntervalChange: InputProps["onChange"] = (event) => {
    const interval = Number.parseInt(event.target.value, 10);

    if (!isNaN(interval) && interval > 0 && interval < 7) {
      onChange?.({ ...options, interval });
    }
  };

  const handleTypeChange: SelectProps<string>["onChange"] = (event) => {
    const value = event.target.value;

    if (value === "day" && !isDayOfMonth) {
      onChange?.({
        ...options,
        dayOfMonth: getDate(start),
        dayOfWeek: undefined,
        index: undefined,
      });
    } else if (value === "the" && isDayOfMonth) {
      onChange?.({
        ...options,
        dayOfWeek: getISODay(start) - 1,
        index: resolveWeekdayIndex(start),
        dayOfMonth: undefined,
      });
    }
  };

  const handleDayOfMonthChange: InputProps["onChange"] = (event) => {
    const dayOfMonth = Number.parseInt(event.target.value, 10);

    if (!isNaN(dayOfMonth) && dayOfMonth >= 1 && dayOfMonth <= getDate(endOfMonth(start))) {
      onChange?.({ ...options, dayOfMonth });
    }
  };

  const handleIndexChange: SelectProps<number>["onChange"] = (event) => {
    const value = event.target.value;
    const index = typeof value === "number" ? value : Number.parseInt(value, 10);

    onChange?.({ ...options, index });
  };

  const handleDayOfWeekChange: SelectProps<number>["onChange"] = (event) => {
    const value = event.target.value;
    const dayOfWeek = typeof value === "number" ? value : Number.parseInt(value, 10);

    onChange?.({ ...options, dayOfWeek });
  };

  const handleEndChange = (end: Date) => {
    onChange?.({
      ...options,
      end: set(end, { hours: start.getHours(), minutes: start.getMinutes(), seconds: start.getSeconds(), milliseconds: start.getMilliseconds() }),
    });
    setEndAnchorEl(null);
  };

  return (
    <>
      <Box alignItems="center" display="flex" flexBasis="50%" flexGrow={0} flexShrink={0} justifyContent="flex-end">
        <Typography color={palette.grey[400]} fontSize={14} mr={1}>{t`every month(s)`}</Typography>
        <FilledInput onChange={handleIntervalChange} sx={{ width: 64 }} type="number" value={interval} />
      </Box>
      <Box alignItems="center" display="flex" flexGrow={0} flexShrink={0} justifyContent="flex-end" pt={1} width="100%">
        <Box display="flex" gap={1} width={350}>
          <Select
            onChange={handleTypeChange}
            sx={{ width: 72 }}
            value={isDayOfMonth ? "day" : "the"}
            variant="filled"
          >
            <MenuItem value={"day"}>{t`Day`}</MenuItem>
            <MenuItem value={"the"}>{t`The`}</MenuItem>
          </Select>
          {isDayOfMonth ? (
            <FilledInput
              onChange={handleDayOfMonthChange}
              sx={{ flex: 1, "& .MuiInputBase-input": { textAlign: "center" } }}
              type="number"
              value={options.dayOfMonth}
            />
          ) : (
            <>
              <Select
                onChange={handleIndexChange}
                sx={{ width: 102 }}
                value={options.index}
                variant="filled"
              >
                <MenuItem value={1}>{t`First`}</MenuItem>
                <MenuItem value={2}>{t`Second`}</MenuItem>
                <MenuItem value={3}>{t`Third`}</MenuItem>
                <MenuItem value={4}>{t`Fourth`}</MenuItem>
                <MenuItem value={-1}>{t`Last`}</MenuItem>
              </Select>
              <Select
                onChange={handleDayOfWeekChange}
                sx={{ flex: 1 }}
                value={options.dayOfWeek}
                variant="filled"
              >
                <MenuItem value={6}>{t`Sunday`}</MenuItem>
                <MenuItem value={0}>{t`Monday`}</MenuItem>
                <MenuItem value={1}>{t`Tuesday`}</MenuItem>
                <MenuItem value={2}>{t`Wednesday`}</MenuItem>
                <MenuItem value={3}>{t`Thursday`}</MenuItem>
                <MenuItem value={4}>{t`Friday`}</MenuItem>
                <MenuItem value={5}>{t`Saturday`}</MenuItem>
              </Select>
            </>
          )}
        </Box>
      </Box>
      <Box alignItems="center" display="flex" flexGrow={0} flexShrink={0} justifyContent="flex-end" pt={1} width="50%">
        <Typography color={palette.grey[400]} fontSize={14} mr={1}>{t`Start`}</Typography>
        <Box alignItems="center" bgcolor={palette.grey[100]} borderRadius={2} display="flex" height={35} minWidth={140} paddingX={1}>
          <Typography fontSize={14} lineHeight={1}>{formatInTimeZone(start, timeZone, "d LLL, yyyy")}</Typography>
        </Box>
      </Box>
      <Box alignItems="center" display="flex" flexGrow={0} flexShrink={0} justifyContent="flex-end" pt={1} width="50%">
        <Typography color={palette.grey[400]} fontSize={14} mr={1}>{t`End`}</Typography>
        <Button
          onClick={(event) => setEndAnchorEl(event.currentTarget)}
          sx={{ alignItems:"center", bgcolor: palette.grey[100], display:"flex", height: 35, justifyContent:"space-between", width: 140 }}
        >
          <Typography color={palette.grey[900]} fontSize={14} lineHeight={1}>{formatInTimeZone(end, timeZone, "d LLL, yyyy")}</Typography>
          <TodayOutlined color="primary" />
        </Button>
      </Box>
      <DayPickerPopover
        anchorEl={endAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        maxDate={maxDate}
        minDate={start}
        onChange={handleEndChange}
        onClose={() => setEndAnchorEl(null)}
        open={!!endAnchorEl}
        sx={{ mt: -1 }}
        timeZone={timeZone}
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
        value={end}
      />
    </>
  );
};

export const RecurrenceOptionsSelect: React.FC<RecurrenceOptionsSelectProps> = (props) => {
  const { value: options, timeZone = "UTC", start, conflicts, maxDate, onChange } = props;
  const { palette, background } = useTheme();
  const [conflictsDialogIsOpen, setConflictsDialogIsOpen] = useState(false);
  const isDisabled = !start;

  useEffect(() => {
    if (options && isDisabled) {
      onChange(undefined);
    }
  }, [isDisabled, options]);

  useEffect(() => {
    if (start && (!options?.start || !isSameMinute(start, options.start))) {
      if (options?.type === "daily") {
        onChange(resolveDefaultDailyRecurrenceOptions({ start, interval: options?.interval }));
      } else if (options?.type === "weekly") {
        onChange(resolveDefaultWeeklyRecurrenceOptions({ start, interval: options?.interval }));
      } else if (options?.type === "monthly") {
        onChange(resolveDefaultMonthlyRecurrenceOptions({ start, interval: options?.interval }));
      }
    }
  }, [options, start?.getTime()]);

  const handleSelectChange: SelectProps<string>["onChange"] = (event) => {
    if (!isDisabled) {
      const type = event.target.value;

      switch (type) {
        case "daily":
          onChange(resolveDefaultDailyRecurrenceOptions({ start }));   
          break;
        case "weekly":
          onChange(resolveDefaultWeeklyRecurrenceOptions({ start }));   
          break;
        case "monthly":
          onChange(resolveDefaultMonthlyRecurrenceOptions({ start }));
          break;
        default:
          onChange(undefined);
          break;
      }
    }
  };

  let conflictsBackground: string | undefined = palette.grey[100];
  let conflictsIcon = <CircularProgress size={20} />;
  let conflictsText = t`Checking for conflicts`;

  if (conflicts) {
    conflictsBackground = conflicts.length ? palette.error.light : background.blue;
    conflictsIcon = conflicts.length ? <BlockRounded fontSize="small" /> : <CheckRounded color="primary" fontSize="small" />;
    conflictsText = conflicts.length ? plural(conflicts.length, { one: "# conflict found", other: "# conflicts found" }) : t`No conflicts found`;
  }

  return (
    <>
      <Tooltip placement="top" title={conflicts?.length ? t`Click to view conflicts` : ""}>
        <Collapse in={!!(start && options?.type)}>
          <ButtonBase
            disabled={!conflicts?.length}
            onClick={() => conflicts?.length ? setConflictsDialogIsOpen(true) : undefined}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "unset",
              bgcolor: conflictsBackground,
              borderRadius: 2,
              mb: 1,
              px: 2,
              py: 1.5,
              width: "100%",
              transition: "background-color .22s",
            }}
          >
            {conflictsIcon}
            <Typography fontSize={14} lineHeight={1} ml={1}>{conflictsText}</Typography>
          </ButtonBase>
        </Collapse>
      </Tooltip>
      <Box display="flex" flexWrap="wrap">
        <Tooltip placement="top" title={isDisabled ? t`Select date and time first` : ""}>
          <Box alignItems="center" display="flex" flexBasis="50%" flexGrow={0} flexShrink={0} justifyContent="space-between">
            <Typography color={isDisabled ? palette.grey[400] : undefined} fontWeight="600">{t`Repeat`}</Typography>
            <Select
              defaultValue="none"
              disabled={isDisabled}
              onChange={handleSelectChange}
              sx={{ minWidth: 140 }}
              value={options?.type || "none"}
              variant="filled"
            >
              {types.map((type) => (
                <MenuItem key={type} value={type}>{resolveRecurrenceTypeLabel(type)}</MenuItem>
              ))}
            </Select>
          </Box>
        </Tooltip>
        {start && options?.type === "daily" ? (
          <DailyOptions
            maxDate={maxDate}
            onChange={onChange}
            options={omitBy(options, isUndefined) as DailyRecurrenceOptions}
            timeZone={timeZone}
          />
        ) : undefined}
        {start && options?.type === "weekly" ? (
          <WeeklyOptions
            maxDate={maxDate}
            onChange={onChange}
            options={omitBy(options, isUndefined) as WeeklyRecurrenceOptions}
            timeZone={timeZone}
          />
        ) : undefined}
        {start && options?.type === "monthly" ? (
          <MonthlyOptions
            maxDate={maxDate}
            onChange={onChange}
            options={omitBy(options, isUndefined) as MonthlyRecurrenceOptions}
            timeZone={timeZone}
          />
        ) : undefined}
      </Box>
      {conflicts?.length ? (
        <RecurrenceConflictsDialog
          conflicts={conflicts || []}
          onClose={() => setConflictsDialogIsOpen(false)}
          open={conflictsDialogIsOpen}
          timeZone={timeZone}
        />
      ) : undefined}
    </>
  );
};
