import React, { useEffect, useState } from "react";
import { UserSelectProps } from "./types";
import { User, useLazySearchUsersQuery } from "store";
import { Autocomplete, Box, TextField, Typography, useTheme } from "@mui/material";
import { t } from "@lingui/macro";
import { useDebouncedCallback } from "hooks";

export const UserSelect: React.FC<UserSelectProps> = (props) => {
  const { value, onChange } = props;
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<User[]>([]);
  const [search, setSearch] = useState("");
  const debouncedSetSearch = useDebouncedCallback(setSearch, [setSearch], 700);
  const [triggerSearchUsersQuery, searchUsersQuery] = useLazySearchUsersQuery();
  const { data: searchUsersQueryResponse, isLoading: usersAreLoading, isFetching: usersAreFetching } = searchUsersQuery;
  const { users = [] } = searchUsersQueryResponse?.result?.data || {};

  useEffect(() => {
    if (isOpen) {
      triggerSearchUsersQuery(search);
    }
  }, [isOpen, search, triggerSearchUsersQuery]);

  useEffect(() => {
    setOptions(users);
  }, [JSON.stringify(users)]);

  return (
    <Autocomplete
      autoComplete
      filterOptions={(x) => x}
      filterSelectedOptions
      getOptionLabel={(option) => option.name || option.email || option.id}
      loading={usersAreLoading || usersAreFetching}
      noOptionsText={t`No users found`}
      onChange={(_, value) => onChange?.(value)}
      onClose={() => setIsOpen(false)}
      onInputChange={(_, value) => debouncedSetSearch(value)}
      onOpen={() => setIsOpen(true)}
      options={options}
      renderInput={(props) => (
        <TextField
          {...props}
          data-cid="search-user-input"
          fullWidth
          placeholder={t`Not selected`}
          sx={{
            "& .MuiFilledInput-root.MuiInputBase-sizeSmall": {
              paddingY: 1,
              paddingLeft: 1,
            },
            "& .MuiFilledInput-input": {
              fontSize: 14,
            },
          }}
          variant="filled"
        />
      )}
      renderOption={(props, option) => (
        <Box {...props} component="li" data-cid="search-user-option">
          <Box>
            <Typography fontSize={14}>{option.name}</Typography>
            <Typography color={theme.palette.grey[700]} fontSize={12} fontWeight="500">{option.email}</Typography>
          </Box>
        </Box>
      )}
      size="small"
      value={value}
    />
  );
};
