import React, { useEffect, useState } from "react";
import { AllDaySwitchProps } from "./types";
import { Box, FormControlLabel, Switch, Tooltip, Typography, useTheme } from "@mui/material";
import { t } from "@lingui/macro";

export const AllDaySwitch: React.FC<AllDaySwitchProps> = (props) => {
  const { checked, unavailable, startDate, endDate, sx, onChange } = props;
  const { palette } = useTheme();
  const [disabled, setDisabled] = useState(false);
  const [tooltip, setTooltip] = useState("");

  useEffect(() => {
    const noDates = !startDate || !endDate;

    if (noDates || unavailable) {
      onChange?.(false);

      if (!disabled) {
        setDisabled(true);
      }
    } else if (!noDates && !unavailable && disabled) {
      setDisabled(false);
    }

    if (noDates) {
      setTooltip(t`Select date and time first`);
    } else if (unavailable) {
      setTooltip(t`Not available on selected date`);
    } else {
      setTooltip("");
    }
  }, [startDate?.getTime(), endDate?.getTime(), disabled, checked]);

  return (
    <Tooltip title={tooltip}>
      <Box alignItems="center" display="flex" sx={sx}>
        <FormControlLabel
          checked={checked}
          control={<Switch data-cid="all-day-switch" size="small" />}
          disabled={disabled}
          label={<Typography color={disabled ? palette.grey[400] : undefined} fontSize={14} ml={1}>{t`All day`}</Typography>}
          onChange={(_, checked) => onChange?.(checked)}
        />
      </Box>
    </Tooltip>
  );
};
