import Api from 'Store/Services/Api';
import { ApproveOrDenyUserDocumentRequestBody, ApproveOrDenyUserDocumentRequestParams } from './models';
import {
  call,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  ADD_USER,
  ADD_USER_FAIL,
  ADD_USER_SUCCESS,
  APPROVE_OR_DENY_USER_DOCUMENT,
  APPROVE_OR_DENY_USER_DOCUMENT_FAIL,
  APPROVE_OR_DENY_USER_DOCUMENT_SUCCESS,
  AddUser,
  AddUserSuccess,
  ApproveOrDenyUserDocument,
  ApproveOrDenyUserDocumentSuccess,
  DELETE_USER,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DeleteUser,
  GET_ALL_USERS,
  GET_ALL_USERS_FAIL,
  GET_ALL_USERS_SUCCESS,
  GET_AVAILABLE_USERS_FROM_AD,
  GET_AVAILABLE_USERS_FROM_AD_FAIL,
  GET_AVAILABLE_USERS_FROM_AD_SUCCESS,
  GET_COUNTRIES_FILTER,
  GET_COUNTRIES_FILTER_FAIL,
  GET_COUNTRIES_FILTER_SUCCESS,
  GET_LIST_ROLES,
  GET_LIST_ROLES_FAIL,
  GET_LIST_ROLES_SUCCESS,
  GetAllUsers,
  GetCountriesFilter,
  GetListRoles,
  GetUsersFromAD,
  UPDATE_LOCATIONS_FOR_LOCAL_ADMIN,
  UPDATE_LOCATIONS_FOR_LOCAL_ADMIN_FAIL,
  UPDATE_LOCATIONS_FOR_LOCAL_ADMIN_SUCCESS,
  UPDATE_USER_ACCOMMODATION,
  UPDATE_USER_ACCOMMODATION_FAIL,
  UPDATE_USER_ACCOMMODATION_SUCCESS,
  UPDATE_USER_ROLE,
  UPDATE_USER_ROLE_FAIL,
  UPDATE_USER_ROLE_SUCCESS,
  UpdateLocationsForLocalAdmin,
  UpdateUserAccommodation,
  UpdateUserRole,
  updateUserLocations,
} from './index';

function* approveOrDenyUserDocumentSaga(action: ApproveOrDenyUserDocument): any {
  try {
    const payload = yield call(Api, action);

    const success: ApproveOrDenyUserDocumentSuccess = {
      type: APPROVE_OR_DENY_USER_DOCUMENT_SUCCESS,
      payload,
      request: {
        queryParams: action.payload.request.data as ApproveOrDenyUserDocumentRequestBody,
        params: action.params as ApproveOrDenyUserDocumentRequestParams,
      },
    };

    yield put(success);
  } catch (e) {
    yield put({ type: APPROVE_OR_DENY_USER_DOCUMENT_FAIL, payload: e });
  }
}

function* getAllUsersSaga(action: GetAllUsers): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_ALL_USERS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_ALL_USERS_FAIL, payload: e });
  }
}

function* getCountriesFilterSaga(action: GetCountriesFilter): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_COUNTRIES_FILTER_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_COUNTRIES_FILTER_FAIL, payload: e });
  }
}

function* getUsersFromADSaga(action: GetUsersFromAD): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_AVAILABLE_USERS_FROM_AD_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_AVAILABLE_USERS_FROM_AD_FAIL, payload: e });
  }
}

function* addUserSaga(action: AddUser): any {
  try {
    const payload = yield call(Api, action);
    const addUserSucessAction: AddUserSuccess = { type: ADD_USER_SUCCESS, payload, updateLocationsRequest: action.updateLocationsRequest };

    yield put(addUserSucessAction);

    // After creating the user, call another action to save the locationIds if role has locationIds
    if (action.updateLocationsRequest.locationIds.length) {
      const updateUserLocationsAction = updateUserLocations({
        userId: addUserSucessAction.payload.data.result.data.id,
        locationIds: action.updateLocationsRequest.locationIds,
      });

      yield put(updateUserLocationsAction);
    }
  } catch (e) {
    yield put({ type: ADD_USER_FAIL, payload: e });
  }
}

function* getUserRoles(action: GetListRoles): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_LIST_ROLES_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_LIST_ROLES_FAIL, payload: e });
  }
}

function* updateUserRoleSaga(action: UpdateUserRole): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: UPDATE_USER_ROLE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: UPDATE_USER_ROLE_FAIL, payload: e });
  }
}

function* updateUserLocationsSaga(action: UpdateLocationsForLocalAdmin): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: UPDATE_LOCATIONS_FOR_LOCAL_ADMIN_SUCCESS, payload });
  } catch (e) {
    yield put({ type: UPDATE_LOCATIONS_FOR_LOCAL_ADMIN_FAIL, payload: e });
  }
}

function* updateUserAccommodationSaga(action: UpdateUserAccommodation): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: UPDATE_USER_ACCOMMODATION_SUCCESS, payload });
  } catch (e) {
    yield put({ type: UPDATE_USER_ACCOMMODATION_FAIL, payload: e });
  }
}

function* deleteUserSaga(action: DeleteUser): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: DELETE_USER_SUCCESS, payload });
  } catch (e) {
    yield put({ type: DELETE_USER_FAIL, payload: e });
  }
}

export default [
  takeLatest(APPROVE_OR_DENY_USER_DOCUMENT, approveOrDenyUserDocumentSaga),
  takeLatest(GET_ALL_USERS, getAllUsersSaga),
  takeLatest(GET_AVAILABLE_USERS_FROM_AD, getUsersFromADSaga),
  takeLatest(GET_COUNTRIES_FILTER, getCountriesFilterSaga),
  takeEvery(ADD_USER, addUserSaga),
  takeLatest(GET_LIST_ROLES, getUserRoles),
  takeLatest(UPDATE_USER_ROLE, updateUserRoleSaga),
  takeLatest(UPDATE_LOCATIONS_FOR_LOCAL_ADMIN, updateUserLocationsSaga),
  takeLatest(UPDATE_USER_ACCOMMODATION, updateUserAccommodationSaga),
  takeLatest(DELETE_USER, deleteUserSaga),
];
