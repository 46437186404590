import React, { useState } from "react";
import { DaySelectorProps } from "./types";
import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import { ArrowBackIosRounded, ArrowForwardIosRounded, TodayRounded } from "@mui/icons-material";
import { DatePickerCalendar } from "../date-picker-calendar";
import { isDaySelectorDisabled } from "./utils";
import { add } from "date-fns";
import { format } from "date-fns-tz";

export const DaySelector: React.FC<DaySelectorProps> = (props) => {
  const { day, disabled, fmt = "d LLL, yyyy", maxDate, timeZone, onChange } = props;
  const [isDatePickerCalendarOpen, setIsDatePickerCalendarOpen] = useState(false);
  const now = new Date();

  const handleDatePickerCalendarChange = (date: Date) => {
    onChange?.(date);
    setIsDatePickerCalendarOpen(false);
  };

  const handleDaySelectorClick = (direction: "next" | "previous") => {
    if (!isDaySelectorDisabled(direction, day, now, timeZone)) {
      onChange?.(direction === "next" ? add(day, { days: 1 }) : add(day, { days: -1 }));
    }
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="space-between"
    >
      <Box alignItems="center" display="flex">
        <Button
          data-cid="open-calendar-button"
          onClick={() => setIsDatePickerCalendarOpen(!isDatePickerCalendarOpen)}
          sx={({ palette }) => ({
            backgroundColor: palette.grey[100],
            color: palette.grey[700],
            padding: .75,
            minWidth: 0,
          })}
        >
          <TodayRounded />
        </Button>
        <Dialog maxWidth={false} onClose={() => setIsDatePickerCalendarOpen(false)} open={isDatePickerCalendarOpen}>
          <Box minWidth={405} padding={3}>
            <DatePickerCalendar date={day} maxDate={maxDate} minDate={now} onChange={handleDatePickerCalendarChange} />
          </Box>
        </Dialog>
        <Typography fontSize={14} fontWeight="600" marginLeft={1}>{format(day, fmt)}</Typography>
      </Box>
      <Box>
        <IconButton
          color="primary"
          data-cid="previous-day-button"
          disabled={disabled || isDaySelectorDisabled("previous", day, now, timeZone)}
          onClick={() => handleDaySelectorClick("previous")}
          size="small"
        >
          <ArrowBackIosRounded fontSize="small" />
        </IconButton>
        <IconButton
          color="primary"
          data-cid="next-day-button"
          disabled={disabled || isDaySelectorDisabled("next", day, now, timeZone, maxDate)}
          onClick={() => handleDaySelectorClick("next")}
          size="small"
        >
          <ArrowForwardIosRounded fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};
