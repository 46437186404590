import React, { useRef } from "react";
import { FileUploadButtonProps } from "./types";
import { Button } from "@mui/material";

export const FileUploadButton: React.FC<FileUploadButtonProps> = (props) => {
  const { uploadOptions, ...rest } = props;
  const { multiple, accept, onChange } = uploadOptions || {};
  const ref = useRef<HTMLInputElement | null>(null);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const fileList = event?.currentTarget?.files;
    const files: File[] = [];

    if (fileList?.length) {
      for (const file of fileList) {
        files.push(file);
      } 
    }
    
    onChange?.(files);
  };

  return (
    <>
      <input
        accept={accept?.join(",")}
        multiple={multiple}
        onChange={handleChange}
        ref={ref}
        style={{
          clip: "rect(0 0 0 0)",
          clipPath: "inset(50%)",
          height: "1px",
          overflow: "hidden",
          position: "absolute",
          whiteSpace: "nowrap",
          width: "1px",
        }}
        type="file"
      />
      <Button data-cid="upload-file-button" {...rest} onClick={() => ref?.current?.click()} />
    </>
  );
};
