import React, { useEffect, useState } from "react";
import { t } from "@lingui/macro";
import { Box, Grid, Tab, TabProps, Tabs, TabsProps, Typography, styled, useTheme } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { HomePageView } from "types";

import { UserGreetings } from "../user-greetings";
import { ReservationCreationButton, UserNotificationsButton } from "../button";
import { SidebarSectionTitle } from "../typography";
import { LastReservationsList, TodaysReservationsList } from "../reservations-list";
import { LinkTree } from "../link-tree";
import { PageContainer } from "../container";
import { useDelegatedId } from "hooks";
import { useGetHomeCountsQuery } from "store";
import { endOfToday } from "date-fns";
import { NotificationsList } from "../notifications";

const paths: Record<HomePageView, { label: string; to: string; cid: string }> = {
  upcoming: { label: t`Upcoming`, to: "/", cid: "upcoming-link" },
  pending: { label: t`Pending`, to: "/pending", cid: "pending-link" },
  saved: { label: t`Saved`, to: "/saved", cid: "saved-link" },
  previous: { label: t`Previous`, to: "/previous", cid: "previous-link" },
};

export const MenuTabs = styled((props: TabsProps) => <Tabs {...props} />)(() => ({
  minHeight: 0,
}));

export const MenuTab = styled((props: TabProps & { index: number; last: boolean }) => <Tab {...props} />)(({ index, last }) => ({
  padding: 0,
  minWidth: 0,
  minHeight: 0,
  textTransform: "capitalize",
  fontWeight: "600",
  fontSize: 16,
  color: "black",
  paddingRight: !last ? 8 : 0,
  paddingLeft: index ? 8 : 0,
  borderRadius: 8,
}));

const MenuBadge: React.FC<{ value?: number }> = (props) => {
  const { value } = props;
  const { palette } = useTheme();

  return (
    <Typography
      alignItems="center"
      bgcolor={palette.secondary.main}
      borderRadius={2}
      display="flex"
      fontWeight="600"
      height={22}
      justifyContent="center"
      ml={1}
      textAlign="center"
      width={22}
    >
      {value}
    </Typography>
  );
};

const MenuLink: React.FC<{ label: string; to: string; cid: string; count?: number }> = ({ label, to, cid, count }) => (
  <Box alignItems="center" component={Link} data-cid={cid} display="flex" py={1} to={to}>
    <Typography fontWeight="600">{label}</Typography>
    {count ? <MenuBadge value={count} /> : undefined}
  </Box>
);

export const HomeWrapper: React.FC = (props) => {
  const { children } = props;
  const { location } = useHistory();
  const delegatedId = useDelegatedId();
  const [tabValue, setTabValue] = useState(0);
  const getHomeCountsQuery = useGetHomeCountsQuery({ userId: delegatedId, endOfDay: endOfToday() });
  const { data: homeCounts } = getHomeCountsQuery?.data?.result || {};

  useEffect(() => {
    const index = Object.values(paths).findIndex(({ to }) => to === location.pathname);

    if (index !== -1 && index !== tabValue) {
      setTabValue(index);
    }
  }, [tabValue, location.pathname, setTabValue]);

  return (
    <PageContainer>
      <Grid container pb={3} pt={4}>
        <Grid display="flex" item justifyContent="space-between" sm={7.5} xl={8}>
          <UserGreetings />
          <Box>
            <ReservationCreationButton />
          </Box>
        </Grid>
        <Grid display="flex" item justifyContent="flex-end" sm={4.5} xl={4}>
          {!delegatedId ? <UserNotificationsButton /> : undefined}  
        </Grid>
      </Grid>
      <Grid container pb={4}>
        <Grid item sm={7.5} xl={8}>
          <TodaysReservationsList />
          <Box mb={2}>
            <MenuTabs onChange={(_, value) => setTabValue(value)} value={tabValue}>
              {Object.entries(paths).map(([key, { label, to, cid }], index, paths) => (
                <MenuTab
                  index={index}
                  key={key}
                  label={<MenuLink cid={cid} count={homeCounts?.[key as HomePageView]} label={label} to={to} />}
                  last={index + 1 === paths.length}
                />
              ))}
            </MenuTabs>
          </Box>
          {children}
        </Grid>
        <Grid alignItems="stretch" display="flex" flexDirection="column" item pl={4} sm={4.5} xl={4}>
          <SidebarSectionTitle mb={2}>{t`Last booked`}</SidebarSectionTitle>
          <LastReservationsList />
          {!delegatedId ? (
            <>
              <SidebarSectionTitle mb={2} mt={3}>{t`Notifications`}</SidebarSectionTitle>
              <NotificationsList limit={2} />
            </>
          ) : undefined}
          <SidebarSectionTitle mb={2} mt={3}>{t`Support links`}</SidebarSectionTitle>
          <LinkTree links={[{ title: t`Helix IT Ticketing system`, url: "https://request.interpublic.com/" }]} />
        </Grid>
      </Grid>
    </PageContainer>
  );
};
