import React, { useEffect, useRef, useState } from "react";
import { ReservationResourceDetailsProps } from "./types";
import { Box, Button, Dialog, Skeleton, Typography, useTheme } from "@mui/material";
import { ReservationType, useLazyGetFloorByIdQuery, useLazyGetRoomByIdQuery } from "store";
import { t } from "@lingui/macro";
import { TogetherFloor, TogetherLocation, TogetherUser } from "../icons";
import { FeatureGroup, ImageOverlay, MapContainer, Polygon } from "react-leaflet";
import Leaflet from "leaflet";
import { getImageBoundsLimited, getMapMinZoomValue } from "App/Pages/CreateNewMeeting/Room/RoomDetails/RoomImages/RoomMap/Map/Helpers/mapFunctions";
import { NavigationButton } from "../button";
import { Close } from "@mui/icons-material";
import { BasicImageCarousel } from "../image-carousel";
import { resolveHeightInRatio } from "utils";
import { HtmlContent } from "../html-content";

// TODO: Really need to improve how we handle floor map, today is really complex with a lot of fixes and hard to get what's happening

// * Right now this expects rooms only, but changes can be made to support other types of reservation resources (desk, etc)

const resolveMapLabel = (type: ReservationType): string | undefined => {
  switch (type) {
    case ReservationType.ROOM:
      return t`Room on map`;
    default:
      return;
  }
};

const resolveDetailsLabel = (type: ReservationType): string | undefined => {
  switch (type) {
    case ReservationType.ROOM:
      return t`Room details`;
    default:
      return;
  }
};

const resolveSelectionLabel = (type: ReservationType): string | undefined => {
  switch (type) {
    case ReservationType.ROOM:
      return t`Select room`;
    default:
      return;
  }
};

const resolveDeselectionLabel = (type: ReservationType): string | undefined => {
  switch (type) {
    case ReservationType.ROOM:
      return t`Deselect room`;
    default:
      return;
  }
};

export const ReservationResourceDetails: React.FC<ReservationResourceDetailsProps> = (props) => {
  const { locationId, floorId, type, Trigger, roomId, isSelected, onSelect } = props;
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const [map, setMap] = useState<Leaflet.Map>();
  const [mapMinZoom, setMapMinZoom] = useState(0);
  const [mapKey, setMapKey] = useState(0);
  const imageOverlayRef = useRef<Leaflet.ImageOverlay>(null);
  const [triggerGetRoomByIdQuery, getRoomByIdQuery] = useLazyGetRoomByIdQuery();
  const [triggerGetFloorByIdQuery, getFloorByIdQuery] = useLazyGetFloorByIdQuery();
  const { data: roomResponse, isLoading: roomIsLoading, isFetching: roomIsFetching } = getRoomByIdQuery;
  const room = roomResponse?.result?.data;
  const roomMapDrawing = room?.extra?.mapDrawing;
  const { data: floorResponse, isLoading: floorIsLoading, isFetching: floorIsFetching } = getFloorByIdQuery;
  const floor = floorResponse?.result?.data;
  const floorMap = floor?.extra?.floorMap;
  const [imageOverlayWidth, imageOverlayHeight] = floorMap?.size || [100, 100];

  useEffect(() => {
    if (isOpen) {
      triggerGetRoomByIdQuery({ locationId, floorId, roomId: roomId || "", include: ["extra.mapDrawing"]}, true);
      
      if (roomMapDrawing) {
        triggerGetFloorByIdQuery({ locationId, floorId, include: ["extra.floorMap"] }, true);
      }
    }
  }, [isOpen, room]);

  useEffect(() => {
    if (isOpen && map) {     
      const mapMinZoom = getMapMinZoomValue({
        imageSize: { width: imageOverlayWidth, height: imageOverlayHeight },
        containerSize: { width: map.getContainer().clientWidth, height: map.getContainer().clientHeight },
      });

      setMapMinZoom(mapMinZoom);
    }
  }, [isOpen, map, imageOverlayWidth, imageOverlayHeight]);

  useEffect(() => {
    if (isOpen && imageOverlayRef.current && map) {
      map.fitBounds(imageOverlayRef.current.getBounds());
    }
  }, [isOpen, imageOverlayRef.current, map, mapMinZoom]);

  useEffect(() => {
    setMapKey((prev) => prev + 1);
  }, [mapMinZoom]);

  const handleSelectionClick = () => {
    setIsOpen(false);
    setTimeout(() => {
      onSelect?.();
    }, 0);
  };

  const imageOverlayBounds = floorMap?.bounds || getImageBoundsLimited({ width: imageOverlayWidth, height: imageOverlayHeight, proportion: 100 });
  const imageWidth = 730;
  const imageHeight = resolveHeightInRatio(imageWidth, "16:9");

  return (
    <Box>
      <Trigger onClick={() => setIsOpen(true)} />
      <Dialog maxWidth="xl" onClose={() => setIsOpen(false)} open={isOpen}>
        <Box display="flex" padding={2}>
          <Box>
            {room?.images?.length ? (
              <Box borderRadius={2} height={imageHeight} marginBottom={2} overflow="hidden" width={imageWidth}>
                <BasicImageCarousel height={imageHeight} images={room?.images?.map(({ url }) => url)} width={imageWidth} />
              </Box>
            ) : undefined}
            <Typography fontSize={18} fontWeight="600" marginBottom={2}>{resolveMapLabel(type)}</Typography>
            <Box
              height={(!roomMapDrawing || !floorMap?.url || !floorMap?.size) && !(roomIsLoading || roomIsFetching || floorIsLoading || floorIsFetching) ? 45 : 300}
              width={730}
            >
              {roomIsLoading || roomIsFetching || floorIsLoading || floorIsFetching ? (
                <Skeleton sx={{ width: 730, height: 300, borderRadius: 2 }} variant="rectangular" />
              ) : (
                <>
                  {!roomMapDrawing || !floorMap?.url || !floorMap?.size ? (
                    <Typography textAlign="center">{t`No associated room location on floor map`}</Typography>
                  ) : (
                    <>
                      <MapContainer
                        attributionControl={false}
                        boundsOptions={{ padding: [0, 0] }}
                        center={[50, 50]}
                        className="app-map"
                        crs={Leaflet.CRS.Simple}
                        key={mapKey}
                        maxZoom={5}
                        minZoom={mapMinZoom}
                        style={{ height: 300, width: 730, borderRadius: 8 }}
                        whenCreated={setMap}
                        zoomSnap={0}
                      >
                        <ImageOverlay bounds={imageOverlayBounds} key={mapKey} ref={imageOverlayRef} url={floorMap?.url} />
                        <FeatureGroup key={mapKey}>
                          <Polygon
                            color={roomMapDrawing?.lineColor}
                            fillColor={roomMapDrawing?.fillColor}
                            positions={roomMapDrawing?.coordinates?.map(({ x, y }) => Leaflet.CRS.Simple.pointToLatLng(Leaflet.point(x, y), 1))}
                          />
                        </FeatureGroup>
                      </MapContainer>   
                    </>
                  )}
                </>
              )}
            </Box>
          </Box>
          <Box paddingLeft={2} width={392}>
            <Box alignItems="center" display="flex" justifyContent="space-between" marginBottom={2}>
              <Typography fontSize={18} fontWeight="600">{resolveDetailsLabel(type)}</Typography>
              <NavigationButton data-cid="close-resource-details-button" onClick={() => setIsOpen(false)}><Close fontSize="small" /></NavigationButton>
            </Box>
            {roomIsLoading || roomIsFetching ? (
              <>
                <Skeleton sx={{ width: 180, borderRadius: 2, height: 24, marginBottom: 1 }} variant="rectangular" />
                <Box display="flex" marginBottom={1}>
                  <Skeleton sx={{ width: 130, borderRadius: 2, height: 28, marginRight: 1 }} variant="rectangular" />
                  <Skeleton sx={{ width: 98, borderRadius: 2, height: 28 }} variant="rectangular" />
                </Box>
                <Skeleton sx={{ width: 280, borderRadius: 2, height: 24, marginBottom: 1 }} variant="rectangular" />
              </>
            ) : (
              <>
                <Typography fontWeight="600" marginBottom={1} noWrap>{room?.name}</Typography>
                <Box display="flex" marginBottom={1}>
                  <Box alignItems="center" bgcolor={theme.palette.grey[100]} borderRadius={2} display="flex" marginRight={1} padding="2px 8px 2px 8px">
                    <TogetherFloor fill={theme.palette.grey[700]} sx={{ width: 16, marginRight: 1 }} />
                    <Typography color={theme.palette.grey[700]} fontSize={14}>{room?.floor?.name}</Typography>
                  </Box>
                  <Box alignItems="flex-end" bgcolor={theme.palette.grey[100]} borderRadius={2} display="flex" padding="2px 8px 2px 8px">
                    <TogetherUser fill={theme.palette.grey[700]} stroke={theme.palette.grey[700]} sx={{ width: 16, marginRight: 1 }} />
                    <Typography color={theme.palette.grey[700]} fontSize={14}>{room?.capacity} {t`Capacity`}</Typography>
                  </Box>
                </Box>
                <Box alignItems="center" display="flex" marginBottom={2}>
                  <TogetherLocation fill={theme.palette.grey[700]} sx={{ width: 16, marginRight: 1 }} />
                  <Typography color={theme.palette.grey[700]} fontSize={14} noWrap>{room?.floor?.location?.address}</Typography>
                </Box>
                {room?.amenities?.length ? (
                  <Box marginBottom={2}>
                    <Typography fontWeight="600" marginBottom={1}>{t`Amenities`}</Typography>
                    <Box display="flex" flexWrap="wrap">
                      {room.amenities.map(({ id, name }) => (
                        <Box bgcolor={theme.palette.grey[100]} borderRadius={2} key={id} marginBottom={1} marginRight={1} padding="2px 8px 2px 8px">
                          <Typography color={theme.palette.grey[700]} fontSize={14}>{name}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ) : undefined}
                {room?.description ? (
                  <Box marginBottom={2}>
                    <Typography fontWeight="600" marginBottom={1}>{t`Description`}</Typography>
                    <Box maxHeight={360} overflow="auto">
                      <HtmlContent html={room.description} />
                    </Box>
                  </Box>
                ) : undefined}
                {onSelect ? (
                  <Button data-cid="select-resource-button" onClick={handleSelectionClick} variant={isSelected ? "outlined" : "contained"}>
                    {isSelected ? resolveDeselectionLabel(type) : resolveSelectionLabel(type)}
                  </Button>
                ) : undefined}
              </>
            )}
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};