import { get, omit, toPairs } from "lodash";
import { ApiTag } from "../enums";
import { togetherApi } from "../together-api";
import { APIResponseBody, CancelReservationEntriesInput, CancelReservationEntryInput, CreateReservationForMe, GetReservationByIdInput, GetReservationsInput, PaginatedAPIResponseBody, Reservation, UpdateMyReservationById, UpdateReservationEntryInput } from "../types";
import { parsePaginationQueryParams } from "../utils";
import { parseObjectQueryParam } from "../../utils";

const reservations = togetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getMyReservationById: builder.query<APIResponseBody<Reservation>, GetReservationByIdInput>({
      query: ({ reservationId, include }) => ({
        url: `/api/users/me/reservations/${reservationId}`,
        params: include?.length 
          ? include.reduce((carry, current, index) => {
            const param = `include[${index}]`;

            return { ...carry, [param]: current };
          }, {})
          : undefined,
      }),
      providesTags: (response) => response?.result?.data?.id ? [{ type: ApiTag.RESERVATION, id: response.result.data.id }] : [],
    }),
    updateMyReservationById: builder.mutation<APIResponseBody<Reservation>, UpdateMyReservationById>({
      query: ({ reservationId, ...body }) => ({
        body,
        url: `/api/users/me/reservations/${reservationId}`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, { reservationId }) => [{ type: ApiTag.RESERVATION, id: reservationId }, { type: ApiTag.ROOM_SCHEDULE }],
    }),
    createReservationForMe: builder.mutation<APIResponseBody<Reservation>, CreateReservationForMe>({
      query: (body) => ({
        body,
        url: "/api/users/me/reservations",
        method: "POST",
      }),
      invalidatesTags: [{ type: ApiTag.ROOM_SCHEDULE }, { type: ApiTag.USER_HOME_COUNTS }, { type: ApiTag.RESERVATION }],
    }),
    getMyLastReservations: builder.query<APIResponseBody<{ items: Reservation[] }>, undefined>({
      query: () => ({ url: "/api/users/me/last-reservations" }),
    }),
    getUserLastReservations: builder.query<APIResponseBody<{ items: Reservation[] }>, { userId: string }>({
      query: ({ userId }) => ({ url: `/api/users/${userId}/last-reservations` }),
    }),
    getReservations: builder.query<PaginatedAPIResponseBody<Reservation>, GetReservationsInput>({
      query: ({ userId, singleEntryListing, ...params }) => ({
        url: `/api/users/${userId || "me"}/reservations`,
        params: {
          ...parsePaginationQueryParams(params),
          ...parseObjectQueryParam("custom", { singleEntryListing }),
        },
      }),
      providesTags: [{ type: ApiTag.RESERVATION }],
      serializeQueryArgs: (parameters) => {
        if (parameters.queryArgs.page) {
          parameters.queryArgs = { ...omit(parameters.queryArgs, "page") };
        }
        
        return parameters;
      },
      merge: (current, next, { arg: parameters }) => {
        if (parameters.page === 1 || parameters.limit === -1) {
          return next;
        } else {
          const { result: { data: { items } } } = current;
        
          return {
            ...next,
            result: {
              ...next.result,
              data: {
                ...next.result.data,
                items: [ ...items, ...next.result.data.items ],
              },
            },
          };
        }
      },
      forceRefetch: (parameters) => {
        if (parameters.previousArg) {
          const previousArguments = omit(parameters.previousArg, "page");
          const currentArguments = omit(parameters.currentArg, "page");

          if (previousArguments && currentArguments) {
            for (const [key, value] of toPairs(currentArguments)) {
              if (value !== get(previousArguments, key)) {
                return true;
              }
            }
          }
        }

        return false;
      },
    }),
    cancelMyReservation: builder.mutation<APIResponseBody<void>, string>({
      query: (reservationId) => ({
        url: `/api/users/me/reservations/${reservationId}/cancel`,
        method: "post",
      }),
      invalidatesTags: [{ type: ApiTag.RESERVATION }, { type: ApiTag.USER_HOME_COUNTS }],
    }),
    getReservationById: builder.query<APIResponseBody<Reservation>, GetReservationByIdInput>({
      query: ({ reservationId, userId, include }) => ({
        url: `/api/users/${userId || "me"}/reservations/${reservationId}`,
        params: include?.length 
          ? include.reduce((carry, current, index) => {
            const param = `include[${index}]`;

            return { ...carry, [param]: current };
          }, {})
          : undefined,
      }),
      providesTags: (response) => response?.result?.data?.id ? [{ type: ApiTag.RESERVATION, id: response.result.data.id }] : [],
    }),
    cancelReservationEntries: builder.mutation<APIResponseBody<Reservation>, CancelReservationEntriesInput>({
      query: ({ reservationId, userId, entriesIds }) => ({
        url: `/api/users/${userId || "me"}/reservations/${reservationId}/entries/cancel`,
        method: "post",
        body: { entriesIds },
      }),
      invalidatesTags: (result, error, { reservationId }) => [{ type: ApiTag.RESERVATION, id: reservationId }],
    }),
    cancelReservationEntry: builder.mutation<APIResponseBody<void>, CancelReservationEntryInput>({
      query: ({ reservationId, entryId, userId }) => ({
        url: `/api/users/${userId || "me"}/reservations/${reservationId}/entries/${entryId}/cancel`,
        method: "post",
      }),
      invalidatesTags: [{ type: ApiTag.RESERVATION }, { type: ApiTag.USER_HOME_COUNTS }],
    }),
    updateReservationEntry: builder.mutation<APIResponseBody<void>, UpdateReservationEntryInput>({
      query: ({ reservationId, entryId, userId, ...body }) => ({
        body,
        url: `/api/users/${userId || "me"}/reservations/${reservationId}/entries/${entryId}`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, { reservationId }) => [{ type: ApiTag.RESERVATION, id: reservationId }, { type: ApiTag.ROOM_SCHEDULE }],
    }),
  }),
});

export const {
  useGetMyReservationByIdQuery,
  useUpdateMyReservationByIdMutation,
  useCreateReservationForMeMutation,
  useGetMyLastReservationsQuery,
  useGetUserLastReservationsQuery,
  useLazyGetMyLastReservationsQuery,
  useLazyGetUserLastReservationsQuery,
  useLazyGetMyReservationByIdQuery,
  useGetReservationsQuery,
  useLazyGetReservationsQuery,
  useCancelMyReservationMutation,
  useGetReservationByIdQuery,
  useCancelReservationEntriesMutation,
  useCancelReservationEntryMutation,
  useUpdateReservationEntryMutation,
} = reservations;
