import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {useTypedSelector} from "../../../../../Store/Redux/store";
import Select from "react-select";
import Api from "../../../../../Store/Services/Api";
import Popup from "reactjs-popup";
import {Trans, t} from "@lingui/macro";
import styles from "./styles.module.scss";
import { isPhoneNumber } from "class-validator";

import { TextField } from "@material-ui/core";
import Box from "../../../../../Components/Box";
import EmailSearching from "./EmailSearching";
import Button from "../../../../../Components/Button";
import { ADD_USER_SUCCESS, addUser, setUserManagementData, updateUserLocations } from "../../../../Store/userManagement";
import { isRestrictedLocationsByRole, selectUserAllowedLocations } from "../../../../../App/Store/Users/profileDuck";

import {
  AddUserRequest,
  UpdateLocationsForLocalAdminModel,
  UserAdModel,
  UserRoleModel,
  UserRoleSelectModel,
} from "../../../../Store/userManagement/models";
import { DropdownIndicator, MultiSelectOption } from "../../../../../Components/Select";

interface AddUsersFormProps {
  open: boolean;
  onCancelAction: any;
}

export interface LocalAdminLocationsInterface {
  value: string;
  label: string;
}

function getUserRolesList(userRoles: UserRoleModel[], isSuperAdmin: boolean) {
  if (userRoles.length) {
    const userRoleList = isSuperAdmin ? [...userRoles] : [...userRoles].filter(role => role.name === 'User');
    return userRoleList.map((role: UserRoleModel) => { return { value: role.id, label: role.name }; });
  }

  return [];
}

export default function AddUsersForm(props: AddUsersFormProps) {
  const dispatch = useDispatch();
  const { config, profile, adminUserManagement, locations } = useTypedSelector(state => state);
  const { open, onCancelAction } = props;

  const { userRoles } = adminUserManagement;
  const isSuperAdmin = profile.roleAccess.superAdmin;
  const userRoleList = getUserRolesList(userRoles, isSuperAdmin);

  const [selectedUser, setSelectedUser] = useState<UserAdModel | null>(null);
  const [fullNameValue, setFullNameValue] = useState('');
  const [roleValue, setRoleValue] = useState<UserRoleSelectModel | null>(userRoleList.length ? userRoleList[0] : null);
  const [locationValue, setLocationValue] = useState<LocalAdminLocationsInterface[]>([]);

  const restrictedLocationsByRole = isRestrictedLocationsByRole(roleValue);
  const allowedLocations = selectUserAllowedLocations(profile, locations).map(location => ({ value: location.id, label: location.locationName }));

  const [selectedUserError, setSelectedUserError] = useState(false);
  const [fullNameUserError, setFullNameUserError] = useState(false);
  const [locationsUserError, setLocationsUserError] = useState(false);

  const fullNameUserErrorClass = fullNameUserError ? styles.formErrorShow : '';
  const locationsUserErrorClass = locationsUserError ? styles.formMultiErrorShow : '';

  useEffect(() => {
    if (selectedUser) {
      const name = selectedUser.displayName || '';
      setFullNameUserError(false);
      setFullNameValue(name);
    }
  }, [selectedUser]);

  // check LocationsUserError error
  useEffect(() => {
    if (locationValue.length) {
      setLocationsUserError(false);
    }
  }, [locationValue]);

  const onRoleChange = (option: any) => {
    setRoleValue(option);
  };

  const onChangeFullName = (event: any) => {
    setFullNameValue(event.target.value);

    if (event.target.value) {
      setFullNameUserError(false);
    }
  };

  const onLocationChange = (option: any) => {
    setLocationValue(option);
  };

  const validateFields = () => {
    let validate = true;

    if (!selectedUser || !fullNameValue || (roleValue?.label === 'Local Admin' && !locationValue.length)) {
      validate = false;
    }

    // set errors messages
    if (!selectedUser) {
      setSelectedUserError(true);
    }

    if (!fullNameValue) {
      setFullNameUserError(true);
    }

    if (!locationValue.length) {
      setLocationsUserError(true);
    }

    return validate;
  };

  const onAddUser = async () => {
    if (!validateFields()) {
      return;
    }

    if (selectedUser && roleValue) {
      const { mail, mobilePhone } = selectedUser;
      const userData: AddUserRequest = {
        email: mail,
        mobilePhone: mobilePhone && isPhoneNumber(mobilePhone) ? mobilePhone : undefined,
        fullName: fullNameValue,
        roleId: roleValue.value,
      };
      const updateLocationsRequest: UpdateLocationsForLocalAdminModel = {
        userId: '',
        locationIds: locationValue.map(l => l.value),
      };

      if (roleValue.label === 'Local Admin') {
        try {
          const action = addUser(userData, updateLocationsRequest);
          const payload = await Api(action);

          if (payload.status === 200 || payload.status === 201) {
            dispatch({ type: ADD_USER_SUCCESS, payload, updateLocationsRequest });
            const newUser = payload.data.result.data;
            const locationIds = locationValue?.length ? locationValue.map(l => l.value) : [];
            dispatch(updateUserLocations({ userId: newUser.id, locationIds }));
          }
        } catch (e: any) {
          let errorText = t`There was an error adding users. Please try again.`;
          const errorMessage = e?.error?.message;
          if (errorMessage) {
            errorText = t`There was an error adding users.` + ` ${errorMessage}. ` + t`Please try again.`;
          }

          dispatch(setUserManagementData({ error: errorText }));
        }
      } else {
        dispatch(addUser(userData, updateLocationsRequest));
      }

      onCancelAction(); // close modal
    }
  };

  return (
    <Popup
      className={'modal'}
      closeOnDocumentClick
      nested
      onClose={onCancelAction}
      open={open}
    >
      <div className="modal-inner">
        <div className="modal-header">
          <h2>
            <Trans>
              Add user
            </Trans>
          </h2>

          <svg className="modal-header__close" height="30px" onClick={() => onCancelAction()} viewBox="0 0 30 30" width="30px">
            <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
              <g id="Booking-savedall" transform="translate(-875.000000, -132.000000)">
                <g id="Group-8" transform="translate(515.000000, 112.000000)">
                  <g id="icons/close" transform="translate(360.000000, 20.000000)">
                    <rect fill={config.theme.primaryLight} height="30" id="Rectangle" rx="8" width="30" x="0" y="0"></rect>
                    <path d="M20.704633,9.29536704 C21.0984557,9.68918977 21.0984557,10.3277026 20.704633,10.7215253 L16.4261582,15 L20.704633,19.2784747 C21.0984557,19.6722974 21.0984557,20.3108102 20.704633,20.704633 C20.3108102,21.0984557 19.6722974,21.0984557 19.2784747,20.704633 L15,16.4261582 L10.7215253,20.704633 C10.3277026,21.0984557 9.68918977,21.0984557 9.29536704,20.704633 C8.90154432,20.3108102 8.90154432,19.6722974 9.29536704,19.2784747 L13.5738418,15 L9.29536704,10.7215253 C8.90154432,10.3277026 8.90154432,9.68918977 9.29536704,9.29536704 C9.68918977,8.90154432 10.3277026,8.90154432 10.7215253,9.29536704 L15,13.5738418 L19.2784747,9.29536704 C19.6722974,8.90154432 20.3108102,8.90154432 20.704633,9.29536704 Z" fill={config.theme.primary}></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className={`modal-inner-content ${styles.modalInnerContent}`}>
          <Box className={''} marginBottom={15}>
            <EmailSearching
              selectedUserError={selectedUserError}
              setSelectedUser={setSelectedUser}
              setSelectedUserError={setSelectedUserError}
            />
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            {selectedUserError ? <div className={styles.formErrors}><Trans>User should be selected.</Trans></div> : null}
          </Box>
          <Box className={''} marginBottom={15}>
            <span className={styles.requiredSign}>*</span><label htmlFor="full-name"><Trans>Full name</Trans></label>
            <TextField
              className={`input input--default input--inline ${fullNameUserErrorClass}`}
              fullWidth
              id="full-name"
              onChange={onChangeFullName}
              placeholder={t`Type here`}
              rows={5}
              value={fullNameValue}
              variant="outlined"
            />
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            {fullNameUserError ? <div className={styles.formErrors}><Trans>This field can't be empty</Trans></div> : null}
          </Box>
          <Box className={''} marginBottom={15}>
            <label htmlFor="role"><Trans>Role</Trans></label>
            <Select
              className={`react-select-custom`}
              classNamePrefix="select-custom"
              components={{ DropdownIndicator }}
              defaultValue={roleValue}
              isClearable={false}
              isDisabled={!isSuperAdmin} // disable choose roles for all roles except super admin
              isSearchable={false}
              onChange={(item) => onRoleChange(item)}
              options={userRoleList}
            />
          </Box>

          {restrictedLocationsByRole &&
          <Box className={''} marginBottom={15}>
            <label htmlFor="role"><Trans>Locations</Trans></label>
            <Select
              className={`react-select-custom ${locationsUserErrorClass}`}
              classNamePrefix="select-custom-multi"
              closeMenuOnSelect={false}
              components={{ DropdownIndicator, Option: MultiSelectOption }}
              defaultValue={locationValue}
              hideSelectedOptions={false}
              isClearable={false}
              isMulti
              onChange={(item) => onLocationChange(item)}
              options={allowedLocations}
              placeholder="Type here"
            />
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            {locationsUserError ? <div className={styles.formErrors}><Trans>This field can't be empty</Trans></div> : null}
          </Box>
          }

          <Box display="flex" justifyContent="end">
            <Button
              aria-label={t`cancel location edition`}
              name={t`cancel location edition`}
              onClick={() => onCancelAction()}
              size="sm"
              type="clear"
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button
              aria-label={t`confirm location edition`}
              disabledStyle={false}
              name={t`confirm location edition`}
              onClick={onAddUser}
              size="sm"
            >
              <Trans>Add user</Trans>
            </Button>
          </Box>
        </div>
      </div>
    </Popup>
  );
}
