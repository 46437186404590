import React, { useState } from "react";
import { Attendee, Desk, Floor, ParkingSpot, Reservation, ReservationServiceType, ReservationStatus, ReservationType, isSuccessAPIResponse, useGetSavedDesksIdQuery, useRemoveSavedDeskMutation, useSaveDeskMutation } from "store";
import { Box, BoxProps, ButtonBase, IconButton, IconButtonProps, Skeleton, Tooltip, Typography, tooltipClasses, useTheme } from "@mui/material";
import { DeepRequired, MarkRequired } from "ts-essentials";
import { formatInTimeZone } from "date-fns-tz";
import { LocationOnOutlined, PictureInPictureAltRounded, StarBorderRounded, StarRounded } from "@mui/icons-material";
import { TogetherClock, TogetherFloor, TogetherParkingSpot, TogetherRoom, TogetherUser } from "../icons";
import { plural, t } from "@lingui/macro";
import { ReservationEntryDetailsDialog } from "../reservation-details";
import { union } from "underscore";
import { Link } from "react-router-dom";
import { ReservationListItemProps } from "./types";
import { differenceInHours, isAfter, isBefore } from "date-fns";
import { formatReservationCountdown, resolveRRuleRecurrenceLabel } from "utils";
import { BasicChip, BlueChip } from "../chip";
import { useDelegatedId, useExceptionTracker } from "hooks";
import { useToast } from "../toast-provider";

const DeskReservationEntry: React.FC<{
  id: string;
  startDate: Date;
  endDate: Date;
  timeZone: string;
  savedDesksAreLoading: boolean;
  mb: number;
  desk: Desk;
  floor: MarkRequired<Floor, "location">;
  bgcolor: BoxProps["bgcolor"];
  legacyId?: string;
  parkingSpot?: ParkingSpot;
  savedDesksIds?: string[];
  showCountdown?: boolean;
  handleSaveDeskClick: IconButtonProps["onClick"];
}> = (props) => {
  const {
    id,
    legacyId,
    startDate,
    endDate,
    desk,
    floor,
    parkingSpot,
    timeZone,
    savedDesksAreLoading,
    bgcolor,
    mb,
    savedDesksIds,
    showCountdown,
    handleSaveDeskClick,
  } = props;
  const { palette } = useTheme();
  const [disableRipple, setDisabledRipple] = useState(false);
  const now = new Date();
  const isFuture = isBefore(now, startDate);

  return (
    <ButtonBase
      component={Link}
      data-cid="desk-reservation-link"
      disableRipple={disableRipple}
      key={id}
      sx={{
        mb,
        bgcolor,
        borderRadius: 2,
        p: 2,
        flexDirection: "column",
        alignItems: "stretch",
      }}
      to={`/booking/${legacyId}/custom/details`}
    >
      <Box alignItems="center" display="flex" justifyContent="space-between" mb={1}>
        <Typography fontSize={14} fontWeight="600">
          {desk.name || t`Unknown desk`}
        </Typography>
        <Box alignItems="center" display="flex">
          <Typography fontSize={14} fontWeight="600" mr={2}>
            {formatInTimeZone(startDate, timeZone, "MMM d yyyy, h:mma")}
            {" - "}
            {formatInTimeZone(endDate, timeZone, "h:mma OOOO")}
          </Typography>
          {savedDesksAreLoading ? (
            <Skeleton height={24} sx={{ bgcolor: palette.grey[300] }} variant="circular" width={24} />
          ) : (
            <IconButton
              onClick={handleSaveDeskClick}
              onMouseEnter={() => !disableRipple ? setDisabledRipple(true) : undefined}
              onMouseLeave={() => disableRipple ? setDisabledRipple(false) : undefined}
              sx={{ p: 0, zIndex: 1 }}
            >
              {savedDesksIds?.includes(desk?.id) ? <StarRounded color="primary" /> : <StarBorderRounded color="primary" />}
            </IconButton>
          )}
        </Box>
      </Box>
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <Box alignItems="center" display="flex">
          <LocationOnOutlined fontSize="small" sx={{ color: palette.grey[700] }} />
          <Typography color={palette.grey[700]} fontSize={14} ml={1}>{floor.location.name}. {floor.location.address}</Typography>
        </Box>
        <Box alignItems="center" display="flex">
          {parkingSpot ? (
            <Box alignItems="center" display="flex" mr={2}>
              <TogetherParkingSpot fill={palette.grey[700]} sx={{ width: 16, height: 16 }} />
              <Typography color={palette.grey[700]} fontSize={14} ml={1}>{t`Floor`} {parkingSpot.floorName}, {parkingSpot.name}</Typography>
            </Box>
          ) : undefined}
          <Box alignItems="center" display="flex">
            <TogetherFloor fill={palette.grey[700]} sx={{ width: 16, height: 16 }} />
            <Typography color={palette.grey[700]} fontSize={14} ml={1}>{floor.name}</Typography>
          </Box>
          {desk?.sectionName ? (
            <Box alignItems="center" display="flex" ml={2}>
              <PictureInPictureAltRounded fontSize="small" sx={{ color: palette.grey[700] }} />
              <Typography color={palette.grey[700]} fontSize={14} ml={1}>{desk.sectionName}</Typography>
            </Box>
          ) : undefined}
        </Box>
      </Box>
      {(showCountdown && isFuture) || desk.amenities ? (
        <Box alignItems="center" display="flex" justifyContent="space-between" mt={2}>
          <Box alignItems="center" display="flex">
            {desk.amenities?.map(({ id, name }, index) => (
              <BasicChip key={id} ml={index ? 1 : 0}>
                <Typography color={palette.grey[700]} fontSize={14}>{name}</Typography>
              </BasicChip>
            ))}
          </Box>
          <Box>
            {showCountdown && isFuture ? (
              <BlueChip py={1}>
                <TogetherClock fill="#fff" sx={{ width: 16, height: 16 }} />
                <Typography color="#fff" fontSize={14} lineHeight={1} ml={0.5}>
                  {formatReservationCountdown(startDate, now)}
                </Typography>
              </BlueChip>
            ) : undefined}
          </Box>
        </Box>
      ) : undefined}
    </ButtonBase>
  );
};

export const DeskReservation: React.FC<{
  reservation: MarkRequired<Reservation, "schedule" | "desk"> & { floor: MarkRequired<Floor, "location"> };
  showCountdown?: boolean;
  mb?: number;
  bgcolor?: string;
}> = (props) => {
  const { reservation, showCountdown, bgcolor, mb } = props;
  const { schedule, desk, floor, children } = reservation;
  const { palette } = useTheme();
  const toast = useToast();
  const trackException = useExceptionTracker();
  const delegatedId = useDelegatedId();
  const [saveDesk, { isLoading: isSavingDesk }] = useSaveDeskMutation();
  const [removeSavedDesk, { isLoading: isRemovingSavedDesk }] = useRemoveSavedDeskMutation();
  const getSavedDesksIdQuery = useGetSavedDesksIdQuery({ userId: delegatedId });
  const { data: getSavedDesksIdResponse, isLoading: savedDesksAreLoading } = getSavedDesksIdQuery;
  const { data: savedDesksIds } = getSavedDesksIdResponse?.result || {};

  const handleSaveDeskClick: IconButtonProps["onClick"] = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (isSavingDesk || isRemovingSavedDesk || savedDesksAreLoading) {
      return;
    }

    if (savedDesksIds?.includes(desk.id)) {
      void (async () => {
        const response = await removeSavedDesk({ deskId: desk.id, userId: delegatedId });
        
        if (!isSuccessAPIResponse(response)) {
          toast.showToast({ severity: "error", message: t`Failed to save desk` });
          trackException(response.error, { endpointName: removeSavedDesk.name });
        }
      })();
    } else {
      void (async () => {
        const response = await saveDesk({ locationId: floor.location.id, floorId: floor.id, deskId: desk.id, userId: delegatedId });

        if (!isSuccessAPIResponse(response)) {
          toast.showToast({ severity: "error", message: t`Failed to remove desk from saved list` });
          trackException(response.error, { endpointName: saveDesk.name });
        }
      })();
    }
  };

  const timeZone = schedule.timeZone?.split(";")[0] || "UTC";
  const { parkingSpot } = children?.find(({ type }) => type === ReservationType.PARKING) || {};

  return (
    <Box alignItems="stretch" display="flex" flexDirection="column" mb={mb}>
      {schedule.entries.map(({ id, startDate: startDateString, endDate: endDateString, legacyId }, index) => (
        <DeskReservationEntry
          bgcolor={bgcolor || palette.grey[100]}
          desk={desk}
          endDate={new Date(endDateString)}
          floor={floor}
          handleSaveDeskClick={handleSaveDeskClick}
          id={id}
          key={id}
          legacyId={legacyId}
          mb={index + 1 === schedule.entries.length ? 0 : 1}
          parkingSpot={parkingSpot}
          savedDesksAreLoading={savedDesksAreLoading}
          savedDesksIds={savedDesksIds}
          showCountdown={showCountdown}
          startDate={new Date(startDateString)}
          timeZone={timeZone}
        />
      ))}
    </Box>
  );
};

export const RoomReservation: React.FC<{
  reservation: MarkRequired<Reservation, "schedule" | "room"> & { floor: MarkRequired<Floor, "location"> };
  showCountdown?: boolean;
  modifiable?: boolean;
  mb?: number;
  bgcolor?: string;
}> = (props) => {
  const { reservation, showCountdown, modifiable, mb, bgcolor } = props;
  const { schedule, room, floor, summary, attendees, externalAttendees } = reservation;
  const { palette } = useTheme();
  const timeZone = schedule.timeZone?.split(";")[0] || "UTC";
  const now = new Date();
  const attendeesCount = (attendees?.length || 0) + (externalAttendees?.length || 0);
  const recurrenceLabel = resolveRRuleRecurrenceLabel(schedule.rrule);
  const isFailed = reservation.status === ReservationStatus.FAILED;
  const title = `${isFailed ? t`Failed` + ": " : ""}${t`Meeting`} - ${summary} ${recurrenceLabel ? ` (${recurrenceLabel})` : ""}`;

  return (
    <Box alignItems="stretch" display="flex" flexDirection="column" mb={mb}>
      {schedule.entries.map(({ id, startDate: startDateString, endDate: endDateString }, index) => {
        const startDate = new Date(startDateString);
        const endDate = new Date(endDateString);
        const isFuture = isBefore(now, startDate);

        return (
          <ReservationEntryDetailsDialog
            Trigger={({ onClick }) => (
              <Tooltip
                placement="bottom-start"
                slotProps={{
                  popper: {
                    sx: { [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: { marginBottom: '4px' } },
                  },
                }}
                title={isFailed ? t`There was a conflict on the calendar. The meeting was not accepted by Outlook.` : ""}
              >
                <ButtonBase
                  data-cid="open-room-reservation-button"
                  onClick={onClick}
                  sx={{
                    mb: index + 1 === schedule.entries.length ? 0 : 1,
                    bgcolor: isFailed ? palette.error.light : bgcolor || palette.grey[100],
                    borderRadius: 2,
                    p: 2,
                    flexDirection: "column",
                    alignItems: "stretch",
                  }}
                >
                  <Box alignItems="center" display="flex" justifyContent="space-between" mb={1}>
                    <Typography fontSize={14} fontWeight="600">{title}</Typography>
                    {schedule.isAllDay && differenceInHours(endDate, startDate) >= 24 ? (
                      <Typography fontSize={14} fontWeight="600">
                        {formatInTimeZone(startDate, timeZone, "MMM d yyyy")}
                        {", " + t`All day` + ", "}
                        {formatInTimeZone(endDate, timeZone, "OOOO")}
                        </Typography>
                    ) : (
                      <Typography fontSize={14} fontWeight="600">
                        {formatInTimeZone(startDate, timeZone, "MMM d yyyy, h:mma")}
                        {" - "}
                        {formatInTimeZone(endDate, timeZone, "h:mma OOOO")}
                      </Typography>
                    )}
                  </Box>
                  <Box alignItems="center" display="flex" justifyContent="space-between">
                    <Box alignItems="center" display="flex">
                      <LocationOnOutlined fontSize="small" sx={{ color: palette.grey[700] }} />
                      <Typography color={palette.grey[700]} fontSize={14} ml={1}>{floor.location.name}. {floor.location.address}</Typography>
                    </Box>
                    <Box alignItems="center" display="flex">
                      <Box alignItems="center" display="flex">
                        <TogetherFloor fill={palette.grey[700]} sx={{ width: 16, height: 16 }} />
                        <Typography color={palette.grey[700]} fontSize={14} ml={1}>{floor.name}</Typography>
                      </Box>
                      <Box alignItems="center" display="flex" ml={2}>
                        <TogetherRoom stroke={palette.grey[700]} sx={{ width: 16, height: 16 }} />
                        <Typography color={palette.grey[700]} fontSize={14} ml={1}>{room?.name || t`Unknown room`}</Typography>
                      </Box>
                      {attendeesCount ? (
                        <Box alignItems="center" display="flex" ml={2}>
                          <TogetherUser fill={palette.grey[700]} sx={{ width: 16, height: 16 }} />
                          <Typography color={palette.grey[700]} fontSize={14} ml={1}>
                            {plural(attendeesCount, { one: "# attendee", other: "# attendees" })}
                          </Typography>
                        </Box>
                      ) : undefined}
                    </Box>
                  </Box>
                  {showCountdown && (isFuture || room.amenities) ? (
                    <Box alignItems="center" display="flex" justifyContent="space-between" mt={2}>
                      <Box alignItems="center" display="flex">
                        {room.amenities?.map(({ id, name }, index) => (
                          <BasicChip key={id} ml={index ? 1 : 0}>
                            <Typography color={palette.grey[700]} fontSize={14}>{name}</Typography>
                          </BasicChip>
                        ))}
                      </Box>
                      <Box>
                        {showCountdown && isFuture ? (
                          <BlueChip py={1}>
                            <TogetherClock fill="#fff" sx={{ width: 16, height: 16 }} />
                            <Typography color="#fff" fontSize={14} lineHeight={1} ml={0.5}>
                              {formatReservationCountdown(startDate, now)}
                            </Typography>
                          </BlueChip>
                        ) : undefined}
                      </Box>
                    </Box>
                  ) : undefined}
                </ButtonBase>
              </Tooltip>
            )}
            entryModifiable={isAfter(startDate, new Date())}
            key={id}
            modifiable={modifiable}
            reservation={{
              title,
              id: reservation.id,
              entryId: id,
              floorName: reservation.floor.name,
              resourceName: reservation.room.name,
              location: { name: reservation.floor.location.name, address: reservation.floor.location.address },
              isRecurrent: !!reservation.schedule.rrule,
              additionalTags: reservation.requestedServices?.map((key) => ({
                [ReservationServiceType.CATERING]: t`I need catering`,
                [ReservationServiceType.IT_SUPPORT]: t`I need IT support`,
              })[key]),
              schedule: { startDate, endDate, timeZone: reservation.schedule.timeZone?.split(";")?.[0] || "UTC" },
              attendees: union<Attendee>(reservation?.attendees || [], reservation?.externalAttendees?.map((email) => ({ email })) || []),
            }}
          />
        );
      })}
    </Box>                  
  );
};

export const VisitReservation: React.FC<{
  reservation: MarkRequired<Reservation, "schedule"> & { floor: MarkRequired<Floor, "location"> };
  mb?: number;
  bgcolor?: string;
}> = (props) => {
  const { reservation, bgcolor, ...rest } = props;
  const { schedule, floor, children } = reservation;
  const { palette } = useTheme();
  const timeZone = schedule.timeZone?.split(";")[0] || "UTC";
  const { parkingSpot } = children?.find(({ type }) => type === ReservationType.PARKING) || {};

  return (
    <Box alignItems="stretch" display="flex" flexDirection="column" {...rest}>
      {schedule.entries.map(({ id, startDate, endDate, legacyId }, index) => (
        <ButtonBase
          component={Link}
          data-cid="visit-reservation-link"
          key={id}
          sx={{
            bgcolor: bgcolor || palette.grey[100],
            borderRadius: 2,
            mb: index + 1 === schedule.entries.length ? 0 : 1,
            p: 2,
            flexDirection: "column",
            alignItems: "stretch",
          }}
          to={`/visit/${legacyId}/custom/details`}
        >
          <Box alignItems="center" display="flex" justifyContent="space-between" mb={1}>
            <Typography fontSize={14} fontWeight="600">
              {t`Visit onsite`}
            </Typography>
            <Typography fontSize={14} fontWeight="600">
              {formatInTimeZone(new Date(startDate), timeZone, "MMM d yyyy, h:mma")}
              {" - "}
              {formatInTimeZone(new Date(endDate), timeZone, "h:mma OOOO")}
            </Typography>
          </Box>
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <Box alignItems="center" display="flex">
              <LocationOnOutlined fontSize="small" sx={{ color: palette.grey[700] }} />
              <Typography color={palette.grey[700]} fontSize={14} ml={1}>{floor.location.name}. {floor.location.address}</Typography>
            </Box>
            <Box alignItems="center" display="flex">
              {parkingSpot ? (
                <Box alignItems="center" display="flex" mr={2}>
                  <TogetherParkingSpot fill={palette.grey[700]} sx={{ width: 16, height: 16 }} />
                  <Typography color={palette.grey[700]} fontSize={14} ml={1}>{t`Floor`} {parkingSpot.floorName}, {parkingSpot.name}</Typography>
                </Box>
              ) : undefined}
              <Box alignItems="center" display="flex">
                <TogetherFloor fill={palette.grey[700]} sx={{ width: 16, height: 16 }} />
                <Typography color={palette.grey[700]} fontSize={14} ml={1}>{floor.name}</Typography>
              </Box>
            </Box>
          </Box>
        </ButtonBase>
      ))}
    </Box>
  );
};

export const ReservationListItem: React.FC<ReservationListItemProps> = (props) => {
  const { reservation: { type, ...reservation }, ...rest } = props;

  if (type === ReservationType.DESK) {
    return <DeskReservation reservation={reservation as DeepRequired<Reservation>} {...rest} />;
  } else if (type === ReservationType.ROOM) {
    return <RoomReservation reservation={reservation as DeepRequired<Reservation>} {...rest} />;
  } else if (type === ReservationType.VISIT) {
    return <VisitReservation reservation={reservation as DeepRequired<Reservation>} {...rest} />;
  }

  return <></>;
};
