import React, { useMemo } from "react";
import { DailyCalendarViewItemButtonProps } from "./types";
import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { TogetherUser } from "../icons";
import { ReservationResourceDetails } from "../reservation-resource-details";
import { InfoOutlined } from "@mui/icons-material";
import { ReservationType } from "store";
import { t } from "@lingui/macro";
import { addDays, isAfter, startOfDay } from "date-fns";
import { isSameDayInTimeZone } from "utils";

export const DailyCalendarViewItemButton: React.FC<DailyCalendarViewItemButtonProps> = (props) => {
  const { item, stepHeight, selectedDay, selection, timeZone, handleRoomSelection } = props;
  const theme = useTheme();
  const disabled = useMemo(
    () => { 
      const maxDate = addDays(startOfDay(new Date()), item.reservationDayLimit || 180);

      return isSameDayInTimeZone(selectedDay, addDays(maxDate, 1), timeZone) || isAfter(selectedDay, maxDate);
    },
    [selectedDay.getTime(), item.reservationDayLimit],
  );

  return (
    <Box display="flex" height={stepHeight} padding={0.5} sx={{ opacity: disabled ? 0.5 : 1 }}>
      <Box
        alignItems="flex-start"
        bgcolor={selection?.id === item.id ? theme.background.blue : theme.palette.grey[100]}
        borderRadius={2}
        display="flex"
        flex="1 0 auto"
        flexDirection="column"
        padding={1}
      >
        <Tooltip title={disabled ? undefined : t`Click to select room`}>
          <Typography
            color={selection?.id === item.id ? "primary" : undefined}
            data-cid="select-resource-button"
            fontSize={14}
            fontWeight="600"
            maxWidth={200}
            noWrap
            onClick={() => !disabled && handleRoomSelection(item.id)}
            sx={{ cursor: disabled ? "default" : "pointer" }}
          >
            {item.name}
          </Typography>
        </Tooltip>
        <Box display="flex" justifyContent="space-between" width="100%">
          {item.capacity ? (
            <Box alignItems="flex-end" display="flex">
              <TogetherUser
                fill={selection?.id === item.id ? theme.palette.primary.main : theme.palette.grey[600]}
                stroke={selection?.id === item.id ? theme.palette.primary.main : theme.palette.grey[600]}
                sx={{ width: 16, marginRight: 0.5 }}
              />
              <Typography color={selection?.id === item.id ? "primary" : theme.palette.grey[600]} fontSize={14}>{item.capacity}</Typography>
            </Box>
          ) : <Box></Box>}
          {disabled ? (
            <IconButton color={selection?.id === item.id ? "primary" : undefined} disabled={disabled} size="small" sx={{ padding: 0 }}>
              <InfoOutlined fontSize="small" />
            </IconButton>
          ) : (
            <ReservationResourceDetails
              Trigger={({ onClick }) => (
                <IconButton color={selection?.id === item.id ? "primary" : undefined} data-cid="open-resource-details-button" onClick={onClick} size="small" sx={{ padding: 0 }}>
                  <InfoOutlined fontSize="small" />
                </IconButton>
              )}
              floorId={item.floorId || ""}
              locationId={item.locationId || ""}
              onSelect={() => handleRoomSelection(item.id)}
              roomId={item.id}
              type={ReservationType.ROOM}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
