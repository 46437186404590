import React from "react";
import { DailyCalendarViewStepProps } from "./types/daily-calendar-view-step-props.type";
import { Box, SxProps, Tooltip } from "@mui/material";

export const DailyCalendarViewStep: React.FC<DailyCalendarViewStepProps> = (props) => {
  const { width, height, xIndex, yIndex, disabled, tooltip, disabledColor, onClick } = props;
  const borderWidth = 1;
  const borderStyle = "solid";
  const sx: SxProps = {
    backgroundColor: "#FFF",
    cursor: disabled ? undefined : "pointer",
    borderRightWidth: borderWidth,
    borderRightStyle: borderStyle,
    borderBottomWidth: borderWidth,
    borderBottomStyle: borderStyle,
  };

  if (yIndex === 0) {
    sx.borderTopWidth = borderWidth;
    sx.borderTopStyle = borderStyle;
  }

  if (xIndex === 0) {
    sx.borderLeftWidth = borderWidth;
    sx.borderLeftStyle = borderStyle;
  }

  const step = (
    <Box
      border="black"
      data-cid="step"
      flex={`0 0 ${width}px`}
      height={height}
      onClick={disabled ? undefined : onClick}
      sx={(({ palette }) => ({
        ...sx,
        borderColor: palette.grey[100],
        bgcolor: disabled ? disabledColor : undefined,
        ":hover": {
          backgroundColor: disabled ? undefined : palette.grey[100],
        },
      }))}
      width={width}
    />
  );

  return tooltip && !disabled ? <Tooltip disableInteractive title={tooltip}>{step}</Tooltip> : step;
};
