import { t } from "@lingui/macro";
import { LocationOnOutlined, PictureInPictureAltRounded, StarBorderRounded, StarRounded } from "@mui/icons-material";
import { Box, IconButton, Skeleton, Typography, useTheme } from "@mui/material";
import { TogetherFloor } from "../icons";
import React, { useEffect } from "react";
import { ReservationType, isSuccessAPIResponse, reservationsSlice, roomsSlice, useGetSavedDesksIdQuery, useLazyGetMyLastReservationsQuery, useLazyGetUserLastReservationsQuery, useRemoveSavedDeskMutation, useSaveDeskMutation } from "store";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setCreateNewBookingData } from "App/Store/Bookings/createNewBookingDuck";
import { setCreateNewVisitData } from "App/Store/Bookings/createNewVisitDuck";
import { useTypedSelector } from "Store/Redux/store";
import { TextButton } from "../button";
import { useToast } from "../toast-provider";
import { useExceptionTracker } from "hooks";

export const LastReservationsList: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { palette } = useTheme();
  const toast = useToast();
  const trackException = useExceptionTracker();
  const userId = useTypedSelector(({ profile }) => profile.id);
  const selectedUserId = useTypedSelector(({ executiveAssistant }) => executiveAssistant.selectedUser?.id);
  const isDelegatedAccess = selectedUserId && selectedUserId !== userId;
  const [saveDesk, { isLoading: isSavingDesk }] = useSaveDeskMutation();
  const [removeSavedDesk, { isLoading: isRemovingSavedDesk }] = useRemoveSavedDeskMutation();
  const getSavedDesksIdQuery = useGetSavedDesksIdQuery({ userId: isDelegatedAccess && selectedUserId ? selectedUserId : undefined });
  const { data: getSavedDesksIdResponse, isLoading: savedDesksAreLoading } = getSavedDesksIdQuery;
  const { data: savedDesksId } = getSavedDesksIdResponse?.result || {};
  const [triggerGetMyLastReservationsQuery, getMyLastReservationsQuery] = useLazyGetMyLastReservationsQuery();
  const [triggerGetUserLastReservationsQuery, getUserLastReservationsQuery] = useLazyGetUserLastReservationsQuery();

  useEffect(() => {
    if (isDelegatedAccess && selectedUserId) {
      triggerGetUserLastReservationsQuery({ userId: selectedUserId });
    } else {
      triggerGetMyLastReservationsQuery(undefined);
    }
  }, [isDelegatedAccess, selectedUserId, userId, triggerGetMyLastReservationsQuery, triggerGetUserLastReservationsQuery]);

  const {
    data: getMyLastReservationsResponse,
    isLoading: myReservationsAreLoading,
    isFetching: myReservationsAreFetching,
  } = getMyLastReservationsQuery;
  const {
    data: getUserLastReservationsResponse,
    isLoading: userReservationsAreLoading,
    isFetching: userReservationsAreFetching,
  } = getUserLastReservationsQuery;
  const { items: myReservations } = getMyLastReservationsResponse?.result?.data || {};
  const { items: userReservations } = getUserLastReservationsResponse?.result?.data || {};
  const reservations = isDelegatedAccess ? userReservations : myReservations;
  const isLoading = myReservationsAreLoading || myReservationsAreFetching || userReservationsAreFetching || userReservationsAreLoading;
  const roomReservation = reservations?.find(({ type }) => type === ReservationType.ROOM);
  const deskReservation = reservations?.find(({ type }) => type === ReservationType.DESK);
  const visitReservation = reservations?.find(({ type }) => type === ReservationType.VISIT);
  const isSavedDesk = deskReservation?.desk?.id && savedDesksId?.includes(deskReservation.desk.id);
  const noReservations = !roomReservation && !deskReservation && !visitReservation;

  const handleRoomReservationBooking = () => {
    if (roomReservation) {
      const { room, floor } = roomReservation;
      const { location } = floor || {};

      if (room && floor && location) {
        dispatch(reservationsSlice.actions.resetCurrent({ roomId: room.id, locationId: location.id, floorId: floor.id }));
        dispatch(roomsSlice.actions.setCurrent({ id: room.id, name: room.name }));
        history.push(`/reservations/locations/${location.id}/floors/${floor.id}/rooms`);
      }
    }
  };

  const handleDeskReservationBooking = () => {
    if (deskReservation) {
      const { floor, desk } = deskReservation;
      const { location } = floor || {};

      if (floor && location) {
        dispatch(setCreateNewBookingData({
          bookingType: "custom",
          dateFrom: undefined,
          dateTo: undefined,
          filters: {},
          floorId: floor.id,
          locationId: location.id,
          parkingSpotId: undefined,
          timeFrom: undefined,
          timeTo: undefined,
          deskId: desk?.id,
        }));
        history.push("/create-new-booking/select-desk");
      }
    }
  };

  const handleVisitReservationBooking = () => {
    if (visitReservation) {
      const { floor } = visitReservation;
      const { location } = floor || {};

      if (floor && location) {
        dispatch(setCreateNewVisitData({
          dateFrom: undefined,
          dateTo: undefined,
          floorId: floor.id,
          locationId: location.id,
          parkingSpotId: undefined,
          timeFrom: undefined,
          timeTo: undefined,
        }));

        history.push("/create-new-visit/onsite/select-date");
      }
    }
  };

  const handleSaveDeskClick = () => {
    const { id: deskId } = deskReservation?.desk || {};
    const { id: floorId } = deskReservation?.floor || {};
    const { id: locationId } = deskReservation?.floor?.location || {};

    if (isSavingDesk || isRemovingSavedDesk || savedDesksAreLoading || !deskReservation || !deskId || !floorId || !locationId) {
      return;
    }

    if (isSavedDesk) {
      void (async () => {
        const response = await removeSavedDesk({ deskId, userId: isDelegatedAccess && selectedUserId ? selectedUserId : undefined });
        
        if (!isSuccessAPIResponse(response)) {
          toast.showToast({ severity: "error", message: t`Failed to save desk` });
          trackException(response.error, { endpointName: removeSavedDesk.name });
        }
      })();
    } else {
      void (async () => {
        const response = await saveDesk({ locationId, floorId, deskId, userId: isDelegatedAccess && selectedUserId ? selectedUserId : undefined });

        if (!isSuccessAPIResponse(response)) {
          toast.showToast({ severity: "error", message: t`Failed to remove desk from saved list` });
          trackException(response.error, { endpointName: saveDesk.name });
        }
      })();
    }
  };

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton height={88} sx={{ mb: 1 }} variant="rectangular" />
          <Skeleton height={88} variant="rectangular" />
        </>
      ) : undefined}
      {!isLoading && noReservations ? (
        <Typography color={palette.grey[500]} textAlign="center">{t`No reservations yet.`}</Typography>
      ) : undefined}
      {!isLoading && roomReservation ? (
        <Box bgcolor={palette.grey[100]} borderRadius={2} mb={1} padding={2}>
          <Box alignItems="center" display="flex" justifyContent="space-between" mb={1}>
            <Typography fontWeight="600">{roomReservation?.room?.name}</Typography>
            <TextButton
              data-cid="book-room-again-button"
              onClick={() => handleRoomReservationBooking()}
              sx={{ cursor: "pointer", color: palette.primary.main, fontSize: 14, fontWeight: "500" }}
            >
              {t`Book Again`}
            </TextButton>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box alignItems="center" display="flex" flexBasis="50%" flexGrow={0} width="50%">
              <LocationOnOutlined fontSize="small" sx={{ color: palette.grey[700] }} />
              <Typography color={palette.grey[700]} fontSize={14} ml={0.5} noWrap title={roomReservation.floor?.location?.address}>
                {roomReservation.floor?.location?.address}
              </Typography>
            </Box>
            <Box alignItems="center" display="flex" justifyContent="flex-end">
              <TogetherFloor fill={palette.grey[700]} sx={{ width: 16 }} />
              <Typography color={palette.grey[700]} fontSize={14} ml={0.5} noWrap title={roomReservation.floor?.name} width={64}>
                {roomReservation.floor?.name}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : undefined}
      {!isLoading && deskReservation ? (
        <Box bgcolor={palette.grey[100]} borderRadius={2} mb={1} padding={2}>
          <Box alignItems="center" display="flex" justifyContent="space-between" mb={1}>
            <Typography fontWeight="600">{deskReservation?.desk?.name}</Typography>
            <Box alignItems="center" display="flex">
              <TextButton
                data-cid="book-desk-again-button"
                onClick={() => handleDeskReservationBooking()}
                sx={{ cursor: "pointer", color: palette.primary.main, fontSize: 14, fontWeight: "500", mr: 1 }}
              >
                {t`Book Again`}
              </TextButton>
              {savedDesksAreLoading ? (
                <Skeleton height={24} sx={{ bgcolor: palette.grey[300] }} variant="circular" width={24} />
              ) : (
                <IconButton data-cid="save-desk-button" onClick={handleSaveDeskClick} sx={{ p: 0 }}>
                  {isSavedDesk ? <StarRounded color="primary" /> : <StarBorderRounded color="primary" />}
                </IconButton>
              )}
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box alignItems="center" display="flex" flexBasis="50%" flexGrow={0} width="50%">
              <LocationOnOutlined fontSize="small" sx={{ color: palette.grey[700] }} />
              <Typography color={palette.grey[700]} fontSize={14} ml={0.5} noWrap title={deskReservation.floor?.location?.address}>
                {deskReservation.floor?.location?.address}
              </Typography>
            </Box>
            <Box alignItems="center" display="flex" justifyContent="flex-end">
              <TogetherFloor fill={palette.grey[700]} sx={{ width: 16 }} />
              <Typography color={palette.grey[700]} fontSize={14} ml={0.5} noWrap title={deskReservation.floor?.name} width={64}>
                {deskReservation.floor?.name}
              </Typography>
              {deskReservation?.desk?.sectionName ? (
                <>
                  <PictureInPictureAltRounded sx={{ color: palette.grey[700], width: 18, transform: "scaleX(0.85)", ml: 1 }} />
                  <Typography color={palette.grey[700]} fontSize={14} ml={0.5} noWrap title={deskReservation.desk.sectionName} width={64}>
                    {deskReservation.desk.sectionName}
                  </Typography>
                </>
              ) : undefined}
            </Box>
          </Box>
        </Box>
      ) : undefined}
      {!isLoading && visitReservation ? (
        <Box bgcolor={palette.grey[100]} borderRadius={2} mb={1} padding={2}>
          <Box alignItems="center" display="flex" justifyContent="space-between" mb={1}>
            <Typography fontWeight="600">{visitReservation?.floor?.name}</Typography>
            <TextButton
              data-cid="book-visit-again-button"
              onClick={() => handleVisitReservationBooking()}
              sx={{ cursor: "pointer", color: palette.primary.main, fontSize: 14, fontWeight: "500" }}
            >
              {t`Book Again`}
            </TextButton>
          </Box>
          <Box alignItems="center" display="flex">
            <LocationOnOutlined fontSize="small" sx={{ color: palette.grey[700] }} />
            <Typography color={palette.grey[700]} fontSize={14} ml={0.5} noWrap title={visitReservation.floor?.location?.address} width={300}>
              {visitReservation.floor?.location?.address}
            </Typography>
          </Box>
        </Box>
      ) : undefined}
    </>
  );
};
