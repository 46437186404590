import {useTypedSelector} from "../../Store/Redux/store";
import { useHistory } from 'react-router';
import { t } from '@lingui/macro';
import styles from './styles.module.scss';

import UserIcon from './assets/profile-icon.png';
import Box from '../Box';
import {ProfileLinkProps} from "../../App/Store/Users/profileDuck/models";
import ExecutiveAssistantProfile from "./ExecutiveAssistantProfile";
import DefaultProfile from "./DefaultProfile";
import {isAnotherUserSelectedByExecutiveAssistant} from "../../App/Store/Users/executiveAssistant/helpers";

export default function ProfileLink(props: ProfileLinkProps) {
  const history = useHistory();
  const { profile, executiveAssistant } = useTypedSelector(state => state);
  const { roleAccess } = profile;
  const { greeting } = props;
  const hasDelegatedAccess = roleAccess.executiveAssistant || roleAccess.delegate || roleAccess.localAdmin || roleAccess.superAdmin;
  const anotherUserSelectedByExecutiveAssistant = isAnotherUserSelectedByExecutiveAssistant(profile, executiveAssistant);
  const clickableProfileImageClass = anotherUserSelectedByExecutiveAssistant ? '' : styles.clickableProfileImage;
  const isAdminClass = greeting === 'admin' ? styles.isAdmin : '';

  const onNavigateProfilePage = () => {
    // prevent navigation for another users
    if (anotherUserSelectedByExecutiveAssistant) {
      return () => {};
    }

    return history.push('/profile');
  };

  return (
    <div className={`${styles.profileWrapper} ${isAdminClass}`}>
      <Box dataTestId="user-profile__profile-icon">
        <div className={`${styles.profileImage} ${clickableProfileImageClass}`} onClick={onNavigateProfilePage}>
          <img alt={t`User Profile`} src={UserIcon} />
        </div>
      </Box>
      <Box className={styles.profileGreeting} dataTestId="user-profile__greeting">
        {hasDelegatedAccess && greeting !== "admin" ? <ExecutiveAssistantProfile /> : <DefaultProfile greeting={greeting} />}
      </Box>
    </div>
  );
}
