import React, { useState } from "react";
import { ReservationEntryDetailsDialogProps } from "./types";
import { Box, Button, Dialog, Divider, List, ListItem, ListItemButton, Popover, Typography, darken, useTheme } from "@mui/material";
import { IconBox } from "../box";
import { TogetherFloor, TogetherRoom, TogetherUser } from "../icons";
import { t } from "@lingui/macro";
import { BasicChip } from "../chip";
import { LocationOnOutlined } from "@mui/icons-material";
import { formatInTimeZone } from "date-fns-tz";
import { useToast } from "../toast-provider";
import { isSuccessAPIResponse, parseResponseError, useCancelMyReservationMutation, useCancelReservationEntryMutation } from "store";
import { ConfirmationDialog } from "components/dialog";
import { Link } from "react-router-dom";

export const ReservationEntryDetailsDialog: React.FC<ReservationEntryDetailsDialogProps> = (props) => {
  const { Trigger, modifiable, entryModifiable, reservation } = props;
  const { palette } = useTheme();
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [cancelPopoverAnchor, setCancelPopoverAnchor] = useState<HTMLButtonElement | null>(null);
  const [editPopoverAnchor, setEditPopoverAnchor] = useState<HTMLButtonElement | null>(null);
  const [cancelMyReservation, { isLoading: isCancelingReservation }] = useCancelMyReservationMutation();
  const [cancelReservationEntry, { isLoading: isCancelingReservationEntry }] = useCancelReservationEntryMutation();

  const handleTriggerClick = () => {
    if (!isCancelingReservation || !isCancelingReservationEntry) {
      setIsOpen(true);
    }
  };

  const handleCancelClick = () => {
    setIsOpen(false);
    
    void (async () => {
      const response = await cancelMyReservation(reservation.id);

      if (isSuccessAPIResponse(response)) {
        toast.showToast({ severity: "success", message: t`Your reservation was canceled` });
      } else {
        const error = parseResponseError(response);

        if (error?.code === 1_001_015) {
          toast.showToast({ severity: "warning", message: t`This reservation is still being processed, please try again later.` });
        } else {
          toast.showToast({ severity: "error", message: t`There was an error while canceling your reservation` });
        }
      }
    })();
  };

  const handleCancelEntryClick = () => {
    setIsOpen(false);
    
    void (async () => {
      const response = await cancelReservationEntry({ reservationId: reservation.id, entryId: reservation.entryId });

      if (isSuccessAPIResponse(response)) {
        toast.showToast({ severity: "success", message: t`Your meeting occurrence was canceled` });
      } else {
        toast.showToast({ severity: "error", message: t`There was an error while canceling your meeting occurrence` });
      }
    })();
  };

  const { title, floorName, resourceName, location, additionalTags, attendees, schedule, isRecurrent } = reservation;
  const formattedDateTime = schedule ? [
    formatInTimeZone(new Date(schedule.startDate), schedule.timeZone, "MMM d, h:mma"),
    formatInTimeZone(new Date(schedule.endDate), schedule.timeZone, "h:mma OOOO"),
  ].join(" - ") : "";

  return (
    <>
      <Trigger onClick={handleTriggerClick} />
      <Dialog onClose={() => setIsOpen(false)} open={isOpen}>
        <Box p={2} width={480}>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Box alignItems="center" display="flex">
            <IconBox><TogetherRoom stroke={palette.grey[700]} sx={{ height: 14, width: 14 }}/></IconBox>
            <Typography fontWeight="600" maxWidth={240} ml={1} noWrap title={title}>{title}</Typography>
          </Box>
          {modifiable || entryModifiable ? (
            isRecurrent ? (
              <>
                <Button onClick={(event) => setEditPopoverAnchor(event.currentTarget)}>{t`Edit meeting`}</Button>
                <Popover
                  anchorEl={editPopoverAnchor}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  onClose={() => setEditPopoverAnchor(null)}
                  open={!!editPopoverAnchor}
                  sx={{ mt: 1 }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                >
                  <List disablePadding>
                    <ListItem disablePadding>
                      <ListItemButton
                        component={Link}
                        data-cid="edit-occurrence-link"
                        disabled={!entryModifiable}
                        divider
                        to={`/reservations/${reservation.id}/entries/${reservation.entryId}/edit`}
                      >
                        <Typography fontSize={14} my={0.5}>{t`Edit this occurrence`}</Typography>
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton component={Link} data-cid="edit-all-occurrences-link" disabled={!modifiable} to={`/reservations/${reservation.id}/edit`}>
                        <Typography fontSize={14} my={0.5}>{t`Edit all occurrences`}</Typography>
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Popover>
              </>
            ) : (
              <Button component={Link} data-cid="edit-reservation-link" to={`/reservations/${reservation.id}/edit`}>{t`Edit meeting`}</Button>
            )
          ) : undefined}
        </Box>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Box mb={2}>
          <Typography fontWeight="600" mb={1}>{t`Details`}</Typography>
          <Box display="flex" mb={1}>
            <BasicChip>
              <TogetherFloor fill={palette.grey[700]} sx={{ height: 14, width: 14 }} />
              <Typography color={palette.grey[700]} fontSize={14} ml={1}>{floorName}</Typography>
            </BasicChip>
            <BasicChip ml={2}>
              <TogetherRoom stroke={palette.grey[700]} sx={{ height: 14, width: 14 }} />
              <Typography color={palette.grey[700]} fontSize={14} ml={1}>{resourceName}</Typography>
            </BasicChip>
          </Box>
          <Box display="flex">
            <LocationOnOutlined fontSize="small" sx={{ color: palette.grey[700] }} />
            <Typography color={palette.grey[700]} fontSize={14} ml={1}>{location.name}. {location.address}</Typography>
          </Box>
        </Box>
        {additionalTags?.length ? (
          <Box mb={2}>
            <Typography fontWeight="600" mb={1}>{t`Additional`}</Typography>
            <Box display="flex" mb={1}>
              {additionalTags.map((tag, index) => (
                <BasicChip key={tag} ml={index > 0 ? 1 : 0}>
                  <Typography color={palette.grey[700]} fontSize={14}>{tag}</Typography>
                </BasicChip>
              ))}
            </Box>
          </Box>
        ) : undefined}
        {attendees?.length ? (
          <Box mb={2}>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <Box alignItems="center" display="flex" mb={1}>
              <IconBox mr={1}><TogetherUser fill={palette.grey[700]} sx={{ height: 14, width: 14 }}/></IconBox>
              <Typography fontWeight="600">{t`Attendees`}: {attendees.length}</Typography>
            </Box>
            <Box maxHeight={220} sx={{ overflowY: "auto", overflowX: "hidden" }}>
              {attendees.map(({ name, email }) => (
                <Box alignItems="center" display="flex" height={44} key={email}>
                  <Box
                    alignItems="center"
                    bgcolor={({ palette }) => !name ? palette.grey[400] : palette.primary.main}
                    borderRadius={2}
                    display="flex"
                    height={30}
                    justifyContent="center"
                    marginRight={1}
                    width={30}
                  >
                    <TogetherUser fill="#fff" sx={{ width: 14 }} />
                  </Box>
                  <Box maxWidth={280}>
                    <Typography fontSize={14} fontWeight="600" noWrap>{name || t`Invited user`}</Typography>
                    <Typography color={({ palette }) => palette.grey[700]} fontSize={12} noWrap>{email}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        ) : undefined}
        <Box alignItems="center" bgcolor={palette.primary.main} borderRadius={2} display="flex" justifyContent="space-between" p={2}>
          <Box>
            <Typography color="#fff" fontSize={14}>{t`Date and time`}</Typography>
            <Typography color="#fff" fontSize={14} maxWidth={modifiable ? 240 : undefined} noWrap={modifiable} title={formattedDateTime}>
              {formattedDateTime}
            </Typography>
          </Box>
          {modifiable || entryModifiable ? (
            isRecurrent ? (
              <>
                <Button onClick={(event) => setCancelPopoverAnchor(event.currentTarget)} sx={{ bgcolor: "#fff", ":hover": { bgcolor: darken("#fff", 0.06) } }}>
                  {t`Cancel meeting`}
                </Button>
                <Popover
                  anchorEl={cancelPopoverAnchor}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  onClose={() => setCancelPopoverAnchor(null)}
                  open={!!cancelPopoverAnchor}
                  sx={{ mt: 1 }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                >
                  <List disablePadding>
                    <ListItem disablePadding>
                      <ConfirmationDialog
                        Trigger={({ onClick }) => (
                          <ListItemButton disabled={!entryModifiable} divider onClick={onClick}>
                            <Typography fontSize={14} my={0.5}>{t`Cancel this occurrence`}</Typography>
                          </ListItemButton>
                        )}
                        description={t`Are you sure you want to cancel this meeting occurrence?`}
                        onConfirm={handleCancelEntryClick}
                        title={t`Cancel meeting occurrence`}
                      />
                    </ListItem>
                    <ListItem disablePadding>
                      <ConfirmationDialog
                        Trigger={({ onClick }) => (
                          <ListItemButton disabled={!modifiable} onClick={onClick}>
                            <Typography fontSize={14} my={0.5}>{t`Cancel all occurrences`}</Typography>
                          </ListItemButton>
                        )}
                        description={t`Are you sure you want to cancel all occurrences for this meeting room reservation?`}
                        onConfirm={handleCancelClick}
                        title={t`Cancel all meeting occurrences`}
                      />
                    </ListItem>
                  </List>
                </Popover>
              </>
            ) : (
              <ConfirmationDialog
                Trigger={({ onClick }) => (
                  <Button disabled={!modifiable} onClick={onClick} sx={{ bgcolor: "#fff", ":hover": { bgcolor: darken("#fff", 0.06) } }}>
                    {t`Cancel meeting`}
                  </Button>
                )}
                description={t`Are you sure you want to cancel this meeting room reservation?`}
                onConfirm={handleCancelClick}
                title={t`Cancel meeting`}
              />
            )
          ) : undefined}
        </Box>
        </Box>
      </Dialog>
    </>
  );
};
