import { t } from "@lingui/macro";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Dialog, FormControl, IconButton, MenuItem, Popover, Select, Skeleton, Switch, TextField, Tooltip, Typography, TypographyProps, useTheme } from "@mui/material";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { AttendeesSearch, CalendarViewSelection, Container, DailyCalendarView, DailyCalendarViewItem, DailyCalendarViewProps, DatePickerCalendar, FromToTimeInput, FromToTimeInputProps, InputLabel, InteractiveTypography, LinkButton, PageHeader, RecurrenceOptionsSelect, RecurrenceOptionsSelectProps, ReservationResourcesList, ReservationResourcesListItem, ReservationResourcesListProps, Tab, TabPanel, Tabs, TextButton, WeeklyCalendarView, WeeklyCalendarViewItem, WeeklyCalendarViewProps, ceiledCalendarViewDate, useToast } from "components";
import { TogetherDateAndTime, TogetherRoom, TogetherUser } from "components/icons";
import { addDays, addWeeks, differenceInDays, differenceInMinutes, endOfDay, isAfter, isEqual, isToday, parse, set, startOfDay } from "date-fns";
import { useDebouncedCallback, useDefaultTimeZone, useExceptionTracker } from "hooks";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Attendee, GetFloorRoomsSchedulesInput, ReservationServiceType, ReservationStatus, floorsSlice, isSuccessAPIResponse, parseResponseError, selectCurrentFloor, selectCurrentRoom, useGetRoomByIdQuery, useGetRoomImagesQuery, useLazyGetFloorRoomsQuery, useLazyGetFloorRoomsSchedulesQuery, useLazyGetFloorsByLocationIdQuery, useLazyGetRoomScheduleConflictsQuery, useUpdateMyReservationByIdMutation } from "store";
import { Reservation, reservationsSlice, roomsSlice, selectCurrentReservation, useGetMyReservationByIdQuery, useGetRoomScheduleQuery, useReservationsAvailabilityQuery } from "store";
import { theme } from "theme";
import { format, formatInTimeZone, toDate, utcToZonedTime } from "date-fns-tz";
import { getNowInTimeZone, getZeroDayInTimeZone, isSameDayInTimeZone, localZeroDay, recurrenceOptionsToRRule, rruleToRecurrenceOptions, safeParseISO, shiftTimeZone, unshiftTimeZone } from "utils";
import { RecurrenceOptions } from "types";
import { AllDaySwitch, AllDaySwitchProps } from "components/switch";

const EditReservationRouteListView: React.FC<{ reservation: Reservation }> = (props) => {
  const { reservation } = props;
  const { room, floor } = reservation;
  const locationId = floor?.location?.id || "";
  const floorId = floor?.id || "";
  const roomId = room?.id || "";
  const selectedItem: ReservationResourcesListItem = {
    locationId,
    floorId,
    id: roomId,
    name: room?.name || "",
    capacity: room?.capacity,
    floorName: floor?.name,
    locationAddress: floor?.location?.address,
  };
  const dispatch = useDispatch();
  const currentReservation = useSelector(selectCurrentReservation);
  const currentFloor = useSelector(selectCurrentFloor);
  const [page, setPage] = useState(1);
  const getRoomImagesQuery = useGetRoomImagesQuery({ locationId, floorId, roomId, limit: -1 });
  const [triggerGetFloorRoomsQuery, getFloorRoomsQuery] = useLazyGetFloorRoomsQuery();
  const [items, setItems] = useState<ReservationResourcesListItem[]>(selectedItem ? [selectedItem] : []);
  const { data: roomsResponse, isLoading: roomsAreLoading, isFetching: roomsAreFetching } = getFloorRoomsQuery;
  const hasMore = !!roomsResponse?.result?.data?.links?.next;
  const rooms = roomsResponse?.result?.data?.items;
  const { data: roomImagesResponse } = getRoomImagesQuery;
  const { items: selectedItemImages = [] } = roomImagesResponse?.result?.data || {};

  useEffect(() => {
    setPage(1);
    setItems(selectedItem ? [{ ...selectedItem, images: selectedItemImages }] : []);
    triggerGetFloorRoomsQuery(
      {
        locationId: floor?.location?.id || "",
        floorId: currentFloor?.id || floor?.id || "",
        page: 1,
        roomsToExclude: selectedItem ? [selectedItem.id] : undefined,
      },
      true,
    );
  }, [currentFloor?.id]);

  useEffect(() => {
    setItems((items) => items.map((item) => {
      if (item.id === selectedItem.id) {
        return { ...item, images: selectedItemImages };
      }

      return item;
    }));
  }, [selectedItemImages]);

  useEffect(() => {
    triggerGetFloorRoomsQuery(
      {
        page,
        locationId: floor?.location?.id || "",
        floorId: currentFloor?.id || floor?.id || "",
        roomsToExclude: selectedItem ? [selectedItem.id] : undefined,
      },
      true,
    );
  }, [page]);

  useEffect(() => {
    const newItems: ReservationResourcesListItem[] = [];

    for (const room of rooms || []) {
      if (!items.some(({ id }) => id === room.id)) {
        newItems.push({
          id: room.id,
          name: room.name,
          floorName: room.floor?.name,
          floorId: room.floor?.id,
          locationId: room?.floor?.location?.id,
          locationAddress: room.floor?.location?.address,
          capacity: room.capacity,
          images: room.images,
        });
      }
    }

    if (newItems.length) {
      setItems([...items, ...newItems]);
    }
  }, [rooms]);

  const handleItemSelect: ReservationResourcesListProps["onItemSelect"] = (item) => {
    const { id: roomId, name: roomName, floorId } = item;

    if (roomId === currentReservation?.roomId) {
      dispatch(reservationsSlice.actions.setCurrent({
        roomId: undefined,
        floorId: undefined,
        startDate: undefined,
        endDate: undefined,
        requestedServices: undefined,
        isAllDay: undefined,
      }));
    } else {
      dispatch(reservationsSlice.actions.setCurrent({
        roomId,
        floorId,
        startDate: undefined,
        endDate: undefined,
        requestedServices: [],
        isAllDay: undefined,
      }));
      dispatch(roomsSlice.actions.resetCurrent({ id: roomId, name: roomName }));
    }
  };

  const handleLoadMore = () => {
    if (hasMore) {
      setPage(page + 1);
    }
  };
  
  return (
    <ReservationResourcesList
      hasMore={hasMore}
      height={710}
      isFirstLoad={page === 1}
      isLoading={roomsAreLoading || roomsAreFetching}
      items={items}
      onItemSelect={handleItemSelect}
      onLoadMore={hasMore ? handleLoadMore : undefined}
      selectedItemId={currentReservation?.roomId}
    />
  );
};

export const EditReservationRouteCalendarView: React.FC<{reservation: Reservation }> = (props) => {
  const { reservation } = props;
  const dispatch = useDispatch();
  const currentFloor = useSelector(selectCurrentFloor);
  const availabilityQuery = useReservationsAvailabilityQuery();
  const [page, setPage] = useState(1);
  const [defaultTimeZone] = useDefaultTimeZone();
  const timeZone = reservation?.schedule?.timeZone?.split(";")?.[0] || defaultTimeZone;
  const zeroDay = getZeroDayInTimeZone(timeZone);
  const selectedDay = parse(availabilityQuery.startDate, "yyyy-MM-dd", zeroDay);
  const locationId = reservation.floor?.location?.id || "";
  const getFloorRoomsSchedulesInput: GetFloorRoomsSchedulesInput = {
    locationId,
    floorId: currentFloor?.id || reservation?.floor?.id || "",
    page,
    startDate: startOfDay(selectedDay),
    endDate: endOfDay(selectedDay),
    limit: 10,
  };
  const [triggerGetFloorRoomsSchedulesQuery, getFloorRoomsSchedulesQuery] = useLazyGetFloorRoomsSchedulesQuery();
  const [items, setItems] = useState<DailyCalendarViewItem[]>([]);
  const currentReservation = useSelector(selectCurrentReservation);
  const { data: roomsSchedulesResponse, isLoading: roomsSchedulesAreLoading, isFetching: roomsSchedulesAreFetching } = getFloorRoomsSchedulesQuery;
  const hasMore = !!roomsSchedulesResponse?.result?.data?.links?.next;
  const roomsSchedules = roomsSchedulesResponse?.result?.data?.items;

  useEffect(() => {
    if (currentReservation?.startDate && !isSameDayInTimeZone(selectedDay, new Date(currentReservation.startDate), defaultTimeZone)) {
      const startDate = format(currentReservation.startDate, "yyyy-MM-dd", { timeZone });
      const endDate = startDate;

      setItems([]);
      setPage(1);
      dispatch(reservationsSlice.actions.setAvailabilityQuery({ startDate, endDate }));
    }
  }, []);

  useEffect(() => {
    setItems([]);
    triggerGetFloorRoomsSchedulesQuery(getFloorRoomsSchedulesInput, true);
  }, [availabilityQuery.startDate, currentFloor?.id]);

  useEffect(() => {
    triggerGetFloorRoomsSchedulesQuery(getFloorRoomsSchedulesInput, true);
  }, [page]);

  useEffect(() => {
    const newItems: DailyCalendarViewItem[] = [];

    for (const roomSchedules of roomsSchedules || []) {
      const { roomId, roomName, floorId, roomCapacity, schedules, roomReservationDayLimit } = roomSchedules;

      if (!items.some(({ id }) => id === roomId)) {
        const item: DailyCalendarViewItem = {
          floorId,
          id: roomId,
          name: roomName || roomId,
          capacity: roomCapacity,
          schedules: [],
          locationId: currentReservation?.locationId,
          reservationDayLimit: roomReservationDayLimit,
        };

        for (const schedule of schedules) {
          const { startDate: startDateString, endDate: endDateString, summary, organizer } = schedule;
          const startDate = utcToZonedTime(toDate(startDateString), timeZone);
          const endDate = utcToZonedTime(toDate(endDateString), timeZone);
          const isCurrentReservationSchedule = reservation.status !== ReservationStatus.FAILED
            && reservation.room
            && reservation.room.id === roomSchedules.roomId
            && reservation?.schedule?.entries?.some((entry) => {
              return isEqual(startDate, new Date(entry.startDate)) && isEqual(endDate, new Date(entry.endDate));
            });

          if (!isCurrentReservationSchedule) {
            item.schedules?.push({ startDate, endDate, summary, organizer });
          }
        }

        newItems.push(item);
      }
    }

    if (newItems.length) {
      setItems([...items, ...newItems]);
    }
  }, [roomsSchedules]);

  useEffect(() => {
    const { roomId } = currentReservation || {};

    if (roomsSchedules && roomId) {
      const { schedules = [] } = roomsSchedules.find((roomSchedule) => roomSchedule.roomId === roomId) || {};

      dispatch(roomsSlice.actions.setCurrent({ schedules }));
    }
  }, [JSON.stringify(roomsSchedules), currentReservation?.roomId]);

  const handleSelectedDayChange = (selectedDay: Date) => {
    const startDate = format(selectedDay, "yyyy-MM-dd", { timeZone });
    const endDate = startDate;
    const roomSchedules = roomsSchedules?.find(({ roomId }) => roomId === currentReservation?.roomId);
    const maxDate = roomSchedules ? addDays(startOfDay(getNowInTimeZone(timeZone)), roomSchedules.roomReservationDayLimit || 180) : undefined;

    if (maxDate && (isSameDayInTimeZone(selectedDay, addDays(maxDate, 1), defaultTimeZone) || isAfter(selectedDay, maxDate))) {
      dispatch(roomsSlice.actions.resetCurrent(undefined));
      dispatch(reservationsSlice.actions.setCurrent({ startDate: undefined, endDate: undefined, roomId: undefined, isAllDay: undefined }));
    } else {
      dispatch(roomsSlice.actions.setCurrent({ schedules: undefined }));
      dispatch(reservationsSlice.actions.setCurrent({ startDate: undefined, endDate: undefined, isAllDay: undefined }));   
    }

    setItems([]);
    setPage(1);
    dispatch(reservationsSlice.actions.setAvailabilityQuery({ startDate, endDate }));
  };

  const handleLoadMore = () => {
    if (hasMore && !roomsSchedulesAreLoading && !roomsSchedulesAreFetching) {
      setPage(page + 1);
    }
  };

  const handleDailyCalendarViewChange: DailyCalendarViewProps["onChange"] = (selection) => {
    if (selection) {
      const { id, start, end } = selection;
      const roomSchedule = roomsSchedules?.find(({ roomId }) => roomId === id);
      const startDate = start ? shiftTimeZone(start, defaultTimeZone) : undefined;
      const endDate = end ? shiftTimeZone(end, defaultTimeZone) : undefined;
      
      if (roomSchedule) {
        dispatch(reservationsSlice.actions.setCurrent({
          startDate: startDate?.toISOString(),
          endDate: endDate?.toISOString(),
          roomId: id,
          floorId: roomSchedule.floorId,
        }));
        dispatch(roomsSlice.actions.resetCurrent({ id, name: roomSchedule.roomName, schedules: roomSchedule.schedules }));
      }
    } else {
      dispatch(reservationsSlice.actions.setCurrent({ startDate: undefined, endDate: undefined, roomId: undefined, isAllDay: undefined }));
      dispatch(roomsSlice.actions.resetCurrent(undefined));
    }
  };

  let defaultSelection: CalendarViewSelection | undefined = undefined;

  if (currentReservation) {
    const { roomId, startDate, endDate, summary } = currentReservation;

    if (roomId) {
      defaultSelection = {
        summary,
        id: roomId,
        start: startDate ? unshiftTimeZone(new Date(startDate), defaultTimeZone) : undefined,
        end: endDate ? unshiftTimeZone(new Date(endDate), defaultTimeZone) : undefined,
      };
    }
  }

  return (
    <DailyCalendarView
      defaultSelection={defaultSelection}
      hasMore={hasMore}
      height={710}
      isFirstLoad={items.length === 0}
      isLoading={roomsSchedulesAreLoading || roomsSchedulesAreFetching}
      items={items}
      onChange={handleDailyCalendarViewChange}
      onLoadMore={handleLoadMore}
      onSelectedDayChange={handleSelectedDayChange}
      selectedDay={selectedDay}
      timeZone={defaultTimeZone}
    />
  );
};

export const EditReservationRouteDetailsRoomSchedule: React.FC<{ reservation: Reservation }> = (props) => {
  const { reservation } = props;
  const { schedule, floor } = reservation;
  const dispatch = useDispatch();
  const currentReservation = useSelector(selectCurrentReservation);
  const [defaultTimeZone] = useDefaultTimeZone();
  const { startDate } = useReservationsAvailabilityQuery();
  const [interval, setInterval] = useState<[Date, Date]>();
  const locationId = floor?.location?.id || "";
  const floorId = floor?.id || "";
  const getRoomByIdQuery = useGetRoomByIdQuery({
    locationId: locationId || currentReservation?.locationId || "",
    floorId: floorId || currentReservation?.floorId || "",
    roomId: currentReservation?.roomId || "",
    include: ["extra.mapDrawing"],
  });
  const { data: roomResponse, isLoading: roomIsLoading, isFetching: roomIsFetching } = getRoomByIdQuery;
  const room = roomResponse?.result?.data;
  const timeZone = schedule?.timeZone?.split(";")?.[0] || defaultTimeZone;
  const getRoomScheduleQuery = useGetRoomScheduleQuery(
    {
      locationId,
      floorId: currentReservation?.floorId || floor?.id || "",
      roomId: currentReservation?.roomId || reservation?.room?.id || "",
      startDate: interval?.[0] || getNowInTimeZone(timeZone),
      endDate: interval?.[1] || getNowInTimeZone(timeZone),
    },
    { skip: !interval },
    );
  const { data: roomScheduleResponse, isLoading: roomScheduleIsLoading, isFetching: roomScheduleIsFetching } = getRoomScheduleQuery;
  const roomSchedule = roomScheduleResponse?.result?.data;
  const maxDate = addDays(startOfDay(getNowInTimeZone(timeZone)), room?.reservationDayLimit || 1);

  useEffect(() => {
    if (roomSchedule) {
      const { schedules } = roomSchedule;

      dispatch(roomsSlice.actions.setCurrent({ schedules }));
    }
  }, [roomSchedule]);

  const handleWeeklyCalendarViewChange: WeeklyCalendarViewProps["onChange"] = (selection) => {
    if (selection) {
      const { start, end, isAllDay } = selection;
      const startDate = start ? shiftTimeZone(start, defaultTimeZone) : undefined;
      const endDate = end ? shiftTimeZone(end, defaultTimeZone) : undefined;

      dispatch(reservationsSlice.actions.setCurrent({ startDate: startDate?.toISOString(), endDate: endDate?.toISOString(), isAllDay }));
    } else {
      dispatch(reservationsSlice.actions.setCurrent({ startDate: undefined, endDate: undefined, isAllDay: undefined }));
    }
  };

  let item: WeeklyCalendarViewItem | undefined = undefined;
  
  if (roomSchedule) {
    const { schedules } = roomSchedule;

    item = {
      id: roomSchedule.roomId,
      schedules: [],
    };

    for (const { startDate: startDateString, endDate: endDateString, organizer, summary } of schedules) {
      const startDate = new Date(startDateString);
      const endDate = new Date(endDateString);
      const skip = reservation.status === ReservationStatus.FAILED ||
        schedule?.entries?.some((entry) => isEqual(startDate, new Date(entry.startDate)) && isEqual(endDate, new Date(entry.endDate)));

      if (!skip) {
        item.schedules?.push({ startDate, endDate, organizer, summary });
      }
    }
  }

  let defaultSelection: CalendarViewSelection | undefined = undefined;

  if (currentReservation) {
    const { roomId, startDate, endDate, recurrence, isAllDay } = currentReservation;

    if (roomId && startDate) {
      defaultSelection = {
        isAllDay,
        id: roomId,
        recurrence: recurrence ? { ...recurrence, start: new Date(recurrence.start), end: new Date(recurrence.end) } : undefined,
        start: startDate ? unshiftTimeZone(new Date(startDate), defaultTimeZone) : undefined,
        end: endDate ? unshiftTimeZone(new Date(endDate), defaultTimeZone) : undefined,
      };
    }
  }
    
  return (
    <WeeklyCalendarView
      defaultSelection={defaultSelection}
      height={362}
      isLoading={roomScheduleIsLoading || roomScheduleIsFetching || roomIsLoading || roomIsFetching}
      item={item}
      maxDate={maxDate}
      onChange={handleWeeklyCalendarViewChange}
      onStartDayChange={(interval) => setInterval(interval)}
      selectedDay={parse(startDate, "yyyy-MM-dd", localZeroDay)}
      timeZone={defaultTimeZone}
    />
  );
};

export const EditReservationRouteDetailsDateAndTime: React.FC<{ timeZone: string }> = (props) => {
  const { timeZone } = props;
  const dispatch = useDispatch();
  const { startDate } = useReservationsAvailabilityQuery();
  const now = getNowInTimeZone(timeZone);
  const selectedDay = parse(startDate, "yyyy-MM-dd", now);

  const handleDatePickerCalendarChange = (selectedDay: Date) => {
    const startDate = format(selectedDay, "yyyy-MM-dd", { timeZone });
    const endDate = startDate;

    dispatch(reservationsSlice.actions.setAvailabilityQuery({ startDate, endDate }));
  };

  return (
    <Box width="100%">
      <Box marginBottom={2}>
        <DatePickerCalendar date={selectedDay} minDate={now} onChange={handleDatePickerCalendarChange} />
      </Box>
    </Box>
  );
};

export const EditReservationRouteDetailsReservation: React.FC<{ reservation: Reservation }> = (props) => {
  const { reservation } = props;
  const dispatch = useDispatch();
  const currentReservation = useSelector(selectCurrentReservation);
  const [summary, setSummary] = useState(currentReservation?.summary);
  const debouncedSetCurrentReservationSummary = useDebouncedCallback((summary?: string) => {
    dispatch(reservationsSlice.actions.setCurrent({ summary }));
  }, [dispatch], 500);
  const getRoomByIdQuery = useGetRoomByIdQuery({
    locationId: reservation?.floor?.location?.id || "",
    floorId: currentReservation?.floorId || reservation?.floor?.id || "",
    roomId: currentReservation?.roomId || reservation?.room?.id || "",
    include: ["extra.mapDrawing"],
  });
  const { data: roomResponse, isLoading: roomIsLoading, isFetching: roomIsFetching } = getRoomByIdQuery;
  const room = roomResponse?.result?.data;
  const attendeesCount = currentReservation?.attendees?.length || 0;
  const availableServices = room?.availableServices?.map?.(({ type }) => type);

  useEffect(() => {
    if (summary !== currentReservation?.summary) {
      setSummary(currentReservation?.summary);
    }
  }, [currentReservation?.summary]);

  useEffect(() => {
    debouncedSetCurrentReservationSummary(summary);
  }, [summary, debouncedSetCurrentReservationSummary]);

  const handleAttendeesSearchChange = (attendees: Attendee[]) => {
    dispatch(reservationsSlice.actions.setCurrent({ attendees }));
  };

  const handleReservationServiceChange = (type: ReservationServiceType, checked: boolean) => {
    const requestedServices = currentReservation?.requestedServices || [];
    const isSelected = requestedServices.includes(type);

    if (checked && !isSelected) {
      dispatch(reservationsSlice.actions.setCurrent({ requestedServices: [...requestedServices, type] }));
    } else if (!checked && isSelected) {
      dispatch(reservationsSlice.actions.setCurrent({ requestedServices: requestedServices.filter((value) => value !== type) }));
    }
  };

  if (roomIsLoading || roomIsFetching) {
    return (
      <Box>
        <Skeleton height={32} sx={({ palette }) => ({ bgcolor: palette.grey[100], borderRadius: 2, marginBottom: 2 })} variant="rectangular" />
        <Skeleton height={32} sx={({ palette }) => ({ bgcolor: palette.grey[100], borderRadius: 2, marginBottom: 2 })} variant="rectangular" />
        <Skeleton height={32} sx={({ palette }) => ({ bgcolor: palette.grey[100], borderRadius: 2, marginBottom: 2 })} variant="rectangular" />
      </Box>
    );
  }

  return (
    <Box marginBottom={2}>
      <Box alignItems="center" display="flex" justifyContent="space-between" marginBottom={2}>
        <Box alignItems="center" display="flex">
          <Box
            alignItems="center"
            bgcolor={theme.palette.grey[100]}
            borderRadius={2}
            display="flex"
            height={32}
            justifyContent="center"
            marginRight={1}
            width={32}
          >
            <TogetherUser fill={theme.palette.grey[700]} stroke={theme.palette.grey[700]} sx={{ width: 16 }} />
          </Box>
          <Typography fontWeight="600">
            {t`Attendees`}{" "}{room?.capacity ? `(${attendeesCount}/${room?.capacity})` : `(${attendeesCount})`}
          </Typography>
        </Box>
        <AttendeesSearch
          Trigger={({ onClick }) => <TextButton data-cid="select-attendees-button" onClick={onClick}>{t`Select`}</TextButton>}
          capacity={room?.capacity}
          onChange={handleAttendeesSearchChange}
          value={currentReservation?.attendees}
        />
      </Box>
      {availableServices?.includes?.(ReservationServiceType.CATERING) ? (
        <Box alignItems="center" display="flex" marginBottom={2}>
          <Box alignItems="center" display="flex" height={32} justifyContent="center" marginRight={1} width={32} >
            <Switch
              checked={currentReservation?.requestedServices?.includes(ReservationServiceType.CATERING) || false}
              onChange={(_, checked) => handleReservationServiceChange(ReservationServiceType.CATERING, checked)}
            />
          </Box>
          <Typography fontWeight="600">{t`I need catering`}</Typography>
        </Box>
      ) : undefined}
      {availableServices?.includes?.(ReservationServiceType.IT_SUPPORT) ? (
        <Box alignItems="center" display="flex" marginBottom={2}>
          <Box alignItems="center" display="flex" height={32} justifyContent="center" marginRight={1} width={32} >
            <Switch
              checked={currentReservation?.requestedServices?.includes(ReservationServiceType.IT_SUPPORT) || false}
              onChange={(_, checked) => handleReservationServiceChange(ReservationServiceType.IT_SUPPORT, checked)}
            />
          </Box>
          <Typography fontWeight="600">{t`I need IT support`}</Typography>
        </Box>
      ) : undefined}
      <FormControl sx={{ marginBottom: 2 }} variant="filled">
        <InputLabel htmlFor="summary" required>{t`Meeting title`}</InputLabel>
        <TextField
          id="summary"
          onChange={(event) => setSummary(event.currentTarget.value)}
          value={summary}
          variant="filled"
        />
      </FormControl>
      <Box alignItems="center" display="flex">
        <Box alignItems="center" display="flex" height={32} justifyContent="center" marginRight={1} width={32} >
          <Switch
            checked={currentReservation?.isTeamsMeeting || false}
            onChange={(_, isTeamsMeeting) => dispatch(reservationsSlice.actions.setCurrent({ isTeamsMeeting }))}
          />
        </Box>
        <Typography fontWeight="600">{t`Create Teams meeting`}</Typography>
      </Box>
    </Box>
  );
};

export const EditReservationRouteDetails: React.FC<{ reservation: Reservation, currentTab: number, onUpdated?: () => void }> = (props) => {
  const { reservation, currentTab, onUpdated } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const trackException = useExceptionTracker();
  const availabilityQuery = useReservationsAvailabilityQuery();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [updateTooltip, setUpdateTooltip] = useState<string>();
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLButtonElement>();
  const currentReservation = useSelector(selectCurrentReservation);
  const currentRoom = useSelector(selectCurrentRoom);
  const [defaultTimeZone, defaultWindowsTimeZone] = useDefaultTimeZone();
  const [updateMyReservationById] = useUpdateMyReservationByIdMutation();
  const toast = useToast();
  const [calendarAnchorEl, setCalendarAnchorEl] = useState<HTMLSpanElement>();
  const timeZone = reservation?.schedule?.timeZone?.split(";")?.[0] || defaultTimeZone;
  const windowsTimeZone = reservation?.schedule?.timeZone?.split(";")?.[0]
    ? reservation?.schedule?.timeZone?.split(";")?.[1] || defaultWindowsTimeZone
    : defaultWindowsTimeZone;
  const zeroDay = getZeroDayInTimeZone(timeZone);
  const [selectedDay, setSelectedDay] = useState(
    reservation.status === ReservationStatus.FAILED && reservation.schedule?.startDate
      ? new Date(reservation.schedule?.startDate)
      : parse(availabilityQuery.startDate, "yyyy-MM-dd", zeroDay),
  );
  const [isCheckingConflicts, setIsCheckingConflicts] = useState<boolean>(false);
  const recurrenceStart = useMemo(() => safeParseISO(currentReservation?.startDate), [currentReservation?.startDate]);
  const locationId = reservation?.floor?.location?.id || "";
  const floorId = reservation?.floor?.id || "";
  const getRoomByIdQuery = useGetRoomByIdQuery({
    locationId: currentReservation?.locationId || locationId || "",
    floorId: currentReservation?.floorId || floorId || "",
    roomId: currentReservation?.roomId || "",
    include: ["extra.mapDrawing"],
  }, {
    skip: (!locationId && !currentReservation?.locationId) || (!floorId && !currentReservation?.floorId),
  });
  const [triggerGetRoomScheduleConflictsQuery, getRoomScheduleConflictsQuery] = useLazyGetRoomScheduleConflictsQuery();
  const { data: roomResponse, isLoading: roomIsLoading, isFetching: roomIsFetching } = getRoomByIdQuery;
  const room = roomResponse?.result?.data;
  const { roomId, startDate, endDate } = currentReservation || {};
  const { data: getRoomScheduleConflictsResponse, isLoading: conflictsAreLoading, isFetching: conflictsAreFetching } = getRoomScheduleConflictsQuery;
  const { conflicts } = getRoomScheduleConflictsResponse?.result?.data || {};
  const now = getNowInTimeZone(timeZone);
  const maxDate = addDays(startOfDay(now), room?.reservationDayLimit || 1);
  const step = 15;

  useEffect(() => {
    if (currentTab === 1 && expanded) {
      setExpanded(false);
    }
  }, [currentTab, setExpanded]);

  useEffect(() => {
    const startDate = safeParseISO(currentReservation?.startDate);

    if (startDate && !isSameDayInTimeZone(startDate, selectedDay, defaultTimeZone)) {
      setSelectedDay(set(startDate, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }));
    }
  }, [currentReservation?.startDate]);

  useEffect(() => {
    if (currentTab === 1) {
      const startDate = parse(availabilityQuery.startDate, "yyyy-MM-dd", zeroDay);

      if (!isSameDayInTimeZone(selectedDay, startDate, defaultTimeZone)) {
        setSelectedDay(startDate);
      }
    }
  }, [currentTab, availabilityQuery.startDate]);

  useEffect(() => {
    const requiredValuesAreSet = !!currentReservation?.roomId 
      && !!currentReservation?.summary
      && !!currentReservation?.startDate
      && !!currentReservation?.endDate;

    if (!requiredValuesAreSet) {
      if (!currentReservation?.roomId) {
        setUpdateTooltip(t`Room is not selected`);
      } else if (!currentReservation?.summary) {
        setUpdateTooltip(t`Meeting title must be set`);
      } else if (!currentReservation?.startDate || !currentReservation?.endDate) {
        setUpdateTooltip(t`Select date and time`);
      }

      if (canUpdate) {
        setCanUpdate(false);
      }
    } else if (!canUpdate && requiredValuesAreSet) {
      setCanUpdate(true);
      setUpdateTooltip(undefined);
    }
  }, [currentReservation, canUpdate, setCanUpdate]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const { startDate, endDate, recurrence, roomId, locationId, floorId } = currentReservation || {};

      if (locationId && floorId && roomId && startDate && endDate && recurrence) {
        const rrule = recurrence 
          ? recurrenceOptionsToRRule({ ...recurrence, start: new Date(recurrence.start), end: new Date(recurrence.end) })?.toString() 
          : undefined;

        if (rrule) {
          triggerGetRoomScheduleConflictsQuery({
            locationId,
            floorId,
            roomId,
            rrule,
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            reservationsToExclude: [reservation.id],
          });
        }
      }

      if (isCheckingConflicts) {
        setIsCheckingConflicts(false);
      }
    }, 700);

    if (!isCheckingConflicts) {
      setIsCheckingConflicts(true);
    }

    dispatch(roomsSlice.actions.setCurrent({ conflicts: undefined }));

    return () => clearTimeout(timeout);
  }, [
    currentReservation?.locationId,
    currentReservation?.floorId,
    currentReservation?.roomId,
    currentReservation?.startDate,
    currentReservation?.endDate,
    currentReservation?.recurrence,
    setIsCheckingConflicts,
    dispatch,
  ]);

  useEffect(() => {
    if (conflictsAreFetching || conflictsAreLoading) {
      dispatch(roomsSlice.actions.setCurrent({ conflicts: undefined }));
    } else {
      dispatch(roomsSlice.actions.setCurrent({ conflicts }));
    }
  }, [conflicts, conflictsAreFetching, conflictsAreLoading, currentReservation?.recurrence, dispatch]);

  const unavailable = useMemo<FromToTimeInputProps["unavailable"]>(() => {
    const unavailable: FromToTimeInputProps["unavailable"] = [];

    if (currentRoom?.schedules) {
      if (currentRoom?.schedules?.length) {
        for (const schedule of currentRoom.schedules) {
          const startDate = new Date(schedule.startDate);

          if (isSameDayInTimeZone(selectedDay, startDate, defaultTimeZone)) {
            const endDate = new Date(schedule.endDate);
            const isReservationSchedule = currentRoom.id === reservation.room?.id
              && reservation.schedule?.entries?.some((entry) => {
                return isEqual(startDate, new Date(entry.startDate)) && isEqual(endDate, new Date(entry.endDate));
              });

            if (!isReservationSchedule) {
              unavailable.push([startDate, endDate]);
            }
          }
        }
      }

      return unavailable.sort(([startA], [startB]) => differenceInMinutes(startA, startB));
    }
  }, [JSON.stringify(currentRoom?.schedules), selectedDay?.toISOString()]);

  const handleDeselect = () => {
    dispatch(reservationsSlice.actions.setCurrent({
      roomId: undefined,
      floorId: undefined,
      startDate: undefined,
      endDate: undefined,
      requestedServices: undefined,
      isAllDay: undefined,
    }));
    dispatch(roomsSlice.actions.resetCurrent());
  };

  const handleChangesReset = () => {
    const startDate = reservation.schedule?.startDate
      ? utcToZonedTime(toDate(reservation.schedule.startDate), defaultTimeZone)?.toISOString()
      : undefined;
    const endDate = reservation.schedule?.endDate
      ? utcToZonedTime(toDate(reservation.schedule.endDate), defaultTimeZone)?.toISOString()
      : undefined;
    const recurrence = rruleToRecurrenceOptions(reservation.schedule?.rrule);

    dispatch(reservationsSlice.actions.resetCurrent({
      startDate,
      endDate,
      locationId: reservation?.floor?.location?.id,
      roomId: reservation.room?.id,
      floorId: reservation.floor?.id,
      attendees: reservation.attendees,
      isTeamsMeeting: reservation.isTeamsMeeting,
      requestedServices: reservation.requestedServices,
      summary: reservation.summary,
      recurrence: recurrence ? { ...recurrence, start: recurrence.start.toISOString(), end: recurrence.end.toISOString() } : undefined,
    }));
    dispatch(roomsSlice.actions.resetCurrent({
      id: reservation.room?.id,
      name: reservation.room?.name,
    }));
  };

  const handleUpdateClick: LoadingButtonProps["onClick"] = (event) => {
    const target = event.currentTarget;
    const timeout = setTimeout(() => setPopoverAnchorEl(target), 5000);

    void (async () => {
      const { roomId, startDate, endDate, attendees, isTeamsMeeting, requestedServices, summary, recurrence, isAllDay } = currentReservation || {};

      if (startDate && endDate) {
        setLoading(true);
  
        const response = await updateMyReservationById({
          roomId,
          isTeamsMeeting,
          requestedServices,
          summary,
          isAllDay,
          startDate: isAllDay ? shiftTimeZone(startOfDay(new Date(startDate)), defaultTimeZone).toISOString() : startDate,
          endDate: isAllDay ? shiftTimeZone(addDays(startOfDay(new Date(startDate)), 1), defaultTimeZone).toISOString() : endDate,
          reservationId: reservation.id,
          attendees: attendees ? attendees.filter(({ id }) => !!id).map(({ id }) => id as string) : undefined,
          externalAttendees: attendees ? attendees.filter(({ id }) => !id).map(({ email }) => email) : undefined,
          rrule: recurrence 
            ? recurrenceOptionsToRRule({ ...recurrence, start: new Date(recurrence.start), end: new Date(recurrence.end) })?.toString()
            : null,
        });

        if (isSuccessAPIResponse(response)) {
          onUpdated?.();
        } else {
          const error = parseResponseError(response);

          if (error?.code === 1_001_015) {
            toast.showToast({ severity: "warning", message: t`This reservation is still being processed, please try again later.` });
          } else {
            toast.showToast({ message: t`There was an error while updating your reservation.`, severity: "error" });
            trackException(error, { endpointName: updateMyReservationById.name });
          }          
        }
  
        clearTimeout(timeout);
        setPopoverAnchorEl(undefined);
        setLoading(false);
      }
    })();
  };

  const handleSelectedDayClick: TypographyProps["onClick"] = (event) => {
    setCalendarAnchorEl(event.currentTarget);
  };

  const handleSelectedDayChange = (value: Date) => {
    dispatch(reservationsSlice.actions.setCurrent({ startDate: undefined, endDate: undefined, isAllDay: undefined }));

    if (currentTab === 0) {
      const availabilityQueryStartDate = parse(availabilityQuery.startDate, "yyyy-MM-dd", now);
      const daysDifference = differenceInDays(value, availabilityQueryStartDate);

      if (daysDifference > 6) {
        const startDate = format(addWeeks(availabilityQueryStartDate, 1), "yyyy-MM-dd", { timeZone: defaultTimeZone });
        const endDate = startDate;

        dispatch(roomsSlice.actions.setCurrent({ schedules: undefined }));
        dispatch(reservationsSlice.actions.setAvailabilityQuery({ ...availabilityQuery, startDate, endDate }));
      } else if (daysDifference < 0) {
        const startDate = format(addWeeks(availabilityQueryStartDate, -1), "yyyy-MM-dd", { timeZone: defaultTimeZone });
        const endDate = startDate;

        dispatch(roomsSlice.actions.setCurrent({ schedules: undefined }));
        dispatch(reservationsSlice.actions.setAvailabilityQuery({ ...availabilityQuery, startDate, endDate }));
      }

      setSelectedDay(value);
      setCalendarAnchorEl(undefined);
    }

    if (currentTab === 1) {
      const startDate = format(value, "yyyy-MM-dd", { timeZone: defaultTimeZone });
      const endDate = startDate;

      dispatch(roomsSlice.actions.setCurrent({ schedules: undefined }));
      dispatch(reservationsSlice.actions.setAvailabilityQuery({ startDate, endDate }));
    }
  };

  const handleFromToTimeInputChange: FromToTimeInputProps["onChange"] = ({ from, to }) => {
    const year = selectedDay.getFullYear();
    const date = selectedDay.getDate();
    const month = selectedDay.getMonth();
    const startDate = set(from, { year, date, month });
    const endDate = set(to, { year, date, month });

    dispatch(reservationsSlice.actions.setCurrent({ startDate: startDate.toISOString(), endDate: endDate.toISOString(), isAllDay: undefined }));
  };

  const handleRecurrenceOptionsChange: RecurrenceOptionsSelectProps["onChange"] = (value) => {
    const recurrence: RecurrenceOptions<string> | undefined = value
      ? { ...value, start: value.start.toISOString(), end: value.end.toISOString() }
      : undefined;

    dispatch(reservationsSlice.actions.setCurrent({ recurrence }));
  };

  const updateButton = (
    <LoadingButton
      data-cid="update-reservation-button"
      disabled={!canUpdate || (currentReservation?.recurrence && !!conflicts?.length) || conflictsAreLoading || conflictsAreFetching}
      loading={loading}
      loadingIndicator={<CircularProgress color="primary" size={22} />}
      onClick={handleUpdateClick}
      sx={{ bgcolor: "#fff", ":hover": { bgcolor: "#fff" } }}
      variant="text"
    >
      {t`Update meeting`}
    </LoadingButton>
  );

  return (
    <Box>
      {reservation?.schedule ? (
        <Box marginBottom={3}>
          <Accordion disableGutters elevation={0} expanded={expanded}>
            <AccordionSummary
              expandIcon={undefined}
              onClick={undefined}
              sx={{
                padding: 0,
                cursor: "default",
                borderBottomStyle: "solid",
                borderBottomWidth: 0.5,
                borderBottomColor: theme.palette.grey[100],
                ":hover:not(.Mui-disabled)": {
                  cursor: "default",
                },
                "&.Mui-focusVisible": {
                  backgroundColor: "unset",
                },
              }}
            >
              <Box flex={1}>
                <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
                  <Box alignItems="center" display="flex">
                    <Box
                      bgcolor={theme.palette.grey[100]}
                      borderRadius={2}
                      display="flex"
                      height={32}
                      justifyContent="center"
                      width={32}
                    >
                      <TogetherDateAndTime fill={theme.palette.grey[700]} sx={{ width: 32 }} />
                    </Box>
                    {roomId && selectedDay ? (
                      <Box alignItems="center" display="flex">
                        {roomIsLoading || roomIsFetching ? (
                          <Skeleton height={20} sx={{ marginX: 1 }} variant="rectangular" width={86} />
                        ) : (
                          <>
                            <InteractiveTypography data-cid="select-day-button" fontSize={14} fontWeight="500" onClick={handleSelectedDayClick}>
                              {format(selectedDay, "d LLL, yyyy.", { timeZone: defaultTimeZone })}
                            </InteractiveTypography>
                            <Popover
                              anchorEl={calendarAnchorEl}
                              anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                              elevation={4}
                              onClose={() => setCalendarAnchorEl(undefined)}
                              open={!!calendarAnchorEl}
                              sx={{ mt: 0.5 }}
                              transformOrigin={{ vertical: "top", horizontal: "center" }}
                            >
                              <Box padding={2} width={360}>
                                <DatePickerCalendar date={selectedDay} maxDate={maxDate} minDate={now} onChange={handleSelectedDayChange} />
                              </Box>
                            </Popover>
                          </>
                        )}
                        <FromToTimeInput
                          isLoading={!currentRoom?.schedules}
                          min={isToday(selectedDay) ? ceiledCalendarViewDate(now, step) : undefined}
                          onChange={handleFromToTimeInputChange}
                          step={step}
                          unavailable={unavailable}
                          value={{
                            from: startDate ? unshiftTimeZone(new Date(startDate), defaultTimeZone) : undefined,
                            to: endDate ? unshiftTimeZone(new Date(endDate), defaultTimeZone) : undefined,
                          }}
                        />
                      </Box>
                    ) : (
                      <Typography fontWeight="600">{t`Select a room`}</Typography>
                    )}
                  </Box>
                  {currentTab === 0 ? (
                    <IconButton color="primary" data-cid="expand-reservation-details-button" onClick={() => setExpanded(!expanded)} size="medium">
                      <KeyboardArrowDown
                        fontSize="medium"
                        sx={{ transform: expanded ? "rotate(180deg)" : "rotate(0deg)", transition: `transform 0.15s` }}
                      />
                    </IconButton>
                  ) : undefined}
                </Box>
                {currentTab === 0 && roomId ? (
                  <Typography color={theme.palette.grey[700]} fontSize={12} fontWeight={500} mt={1}>
                    {t`* All times shown are in`}
                    {" "}
                    <Tooltip disableInteractive title={windowsTimeZone ? t`Derived from "${windowsTimeZone}" time zone.` : undefined}>
                      <Box
                        bgcolor={theme.palette.grey[100]}
                        borderRadius={1}
                        color={theme.palette.grey[800]}
                        component="span"
                        display="inline-block"
                        paddingX={0.5}
                        paddingY={0.25}
                      >
                        {timeZone}
                      </Box>
                    </Tooltip>
                    {" "}
                    {t`time zone.`}
                  </Typography>
                ) : undefined}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              {currentReservation?.roomId && currentTab === 0 ? (
                <EditReservationRouteDetailsRoomSchedule reservation={reservation} />
              ) : (
                <EditReservationRouteDetailsDateAndTime timeZone={timeZone} />
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
      ) : undefined}
      {currentRoom?.id ? (
        <Box mb={2}>
          <RecurrenceOptionsSelect
            conflicts={currentRoom?.conflicts?.map((conflict) => ({
              startDate: new Date(conflict.startDate),
              endDate: new Date(conflict.endDate),
              schedule: {
                ...conflict.schedule,
                startDate: new Date(conflict.schedule.startDate),
                endDate: new Date(conflict.schedule.endDate),
              },
            }))}
            maxDate={maxDate}
            onChange={handleRecurrenceOptionsChange}
            start={recurrenceStart}
            timeZone={defaultTimeZone}
            value={
              currentReservation?.recurrence ? ({
                ...currentReservation?.recurrence,
                start: new Date(currentReservation.recurrence.start),
                end: new Date(currentReservation.recurrence.end),
              }) : undefined
            }
          />
        </Box>
      ) : undefined}
      <Box alignItems="center" display="flex" justifyContent="space-between" marginBottom={2}>
        <Box alignItems="center" display="flex">
          <Box
            bgcolor={theme.palette.grey[100]}
            borderRadius={2}
            display="flex"
            height={32}
            justifyContent="center"
            marginRight={1}
            width={32}
          >
            <TogetherRoom stroke={theme.palette.grey[700]} sx={{ width: 16 }} />
          </Box>
          <Typography fontWeight="600">
            {currentRoom?.name ? currentRoom.name : t`Room is not selected`}
          </Typography>
        </Box>
        {currentReservation?.roomId && currentTab === 0 ? (
          <LinkButton data-cid="deselect-resource-button" onClick={handleDeselect}>{t`Deselect`}</LinkButton>
        ) : undefined}
      </Box>
      {currentReservation?.roomId ? <EditReservationRouteDetailsReservation reservation={reservation} /> : undefined}
      <Box
        alignItems="center"
        bgcolor={({ palette }) => palette.primary.main}
        borderRadius={2}
        display="flex"
        justifyContent="space-between"
        padding={2}
      >
        <LinkButton data-cid="reset-reservation-button" onClick={handleChangesReset} sx={{ color: "rgba(255, 255, 255, 0.6)" }}>{t`Reset changes`}</LinkButton>
        {canUpdate ? updateButton : <Tooltip title={updateTooltip}><span>{updateButton}</span></Tooltip>}
      </Box>
      <Popover
        anchorEl={popoverAnchorEl}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        elevation={4}
        open={!!popoverAnchorEl}
        sx={{ mt: -0.5 }}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Box padding={2} width={280}>
          <Typography color={theme.palette.grey[700]} fontSize={14} textAlign="center">
            {t`Please, wait while we send your reservation to Outlook`}
          </Typography>
        </Box>
      </Popover>
    </Box>
  );
};

export const EditReservationRouteFloorSelector: React.FC<{ locationId?: string; defaultFloorId?: string }> = (props) => {
  const { locationId, defaultFloorId } = props;
  const dispatch = useDispatch();
  const currentFloor = useSelector(selectCurrentFloor);
  const [getFloorsByLocationIdQueryTrigger, getFloorsByLocationIdQuery] = useLazyGetFloorsByLocationIdQuery();
  const { data: floorsResponse, isLoading: areFloorsLoading, isFetching: areFloorsFetching } = getFloorsByLocationIdQuery;
  const floors = floorsResponse?.result?.data?.items;
  
  useEffect(() => {
    if (locationId) {
      getFloorsByLocationIdQueryTrigger({ locationId, limit: -1, include: ["extra.availableRooms"] });
    }
  }, [locationId]);

  return areFloorsLoading || areFloorsFetching || !floors ? (
    <Skeleton sx={{ width: 140, height: 24, borderRadius: 2 }} variant="rectangular" />
  ) : (
    <Box alignItems="flex-end" display="flex">
      <InputLabel disableAnimation id="floor-select-label" sx={{ fontSize: 16, fontWeight: "600", marginRight: 1, marginBottom: 0 }}>
        {t`Floor`},
      </InputLabel>
      <Select
        IconComponent={KeyboardArrowDown}
        disableUnderline
        labelId="floor-select-label"
        onChange={(event) => dispatch(floorsSlice.actions.resetCurrent({ id: event.target.value }))}
        size="small"
        sx={({ palette }) => ({
          "& .MuiSelect-select": {
            padding: 0,
            fontSize: 16,
            fontWeight: "600",
          },
          "& .MuiSelect-icon": {
            color: palette.primary.main,
            top: 0,
          },
        })}
        value={currentFloor?.id || defaultFloorId}
        variant="standard"
      >
        {floors.map(({ id, name, extra }) => (
          <MenuItem disabled={!extra?.availableRooms} key={id} value={id}>{name}</MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export const EditReservationRoute: React.FC = () => {
  const dispatch = useDispatch();
  const { reservationId } = useParams<{ reservationId: string }>();
  const currentReservation = useSelector(selectCurrentReservation);
  const currentRoom = useSelector(selectCurrentRoom);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [defaultTimeZone, defaultWindowsTimeZone] = useDefaultTimeZone();
  const allDayIsAvailable = useMemo(() => {
    const { roomId, startDate } = currentReservation || {};

    if (roomId && startDate) {
      if (currentRoom?.id !== roomId || currentRoom?.schedules?.length) {
        return false;
      }

      return true;
    }

    return false;
  }, [JSON.stringify(currentRoom), currentReservation?.roomId, currentReservation?.startDate, currentReservation?.endDate]);
  const { data: reservationResponse, isLoading: reservationIsLoading, isFetching: reservationIsFetching } = useGetMyReservationByIdQuery({
    reservationId,
    include: ["schedule", "schedule.entries", "attendees", "floor", "floor.location", "room"],
  });
  const reservation = reservationResponse?.result?.data;
  const { room, floor, schedule, status } = reservation || {};
  const timeZone = schedule?.timeZone?.split(";")?.[0] || defaultTimeZone;
  const windowsTimeZone = schedule?.timeZone?.split(";")?.[0]
    ? schedule?.timeZone?.split(";")?.[1] || defaultWindowsTimeZone
    : defaultWindowsTimeZone;

  useEffect(() => {
    const attendees: Attendee[] = [];

    if (reservation?.attendees?.length) {
      attendees.push(...reservation.attendees);
    }

    if (reservation?.externalAttendees?.length) {
      attendees.push(...reservation.externalAttendees.map((email) => ({ email })));
    }

    const recurrence = rruleToRecurrenceOptions(reservation?.schedule?.rrule);

    dispatch(reservationsSlice.actions.resetCurrent({
      attendees,
      roomId: room?.id,
      floorId: floor?.id,
      locationId: floor?.location?.id,
      startDate: status === ReservationStatus.BOOKED ? schedule?.startDate : undefined,
      endDate: status === ReservationStatus.BOOKED ? schedule?.endDate : undefined,
      isAllDay: schedule?.isAllDay,
      summary: reservation?.summary,
      isTeamsMeeting: reservation?.isTeamsMeeting,
      requestedServices: reservation?.requestedServices,
      recurrence: recurrence ? { ...recurrence, start: recurrence.start.toISOString(), end: recurrence.end.toISOString() } : undefined,
    }));
    dispatch(roomsSlice.actions.resetCurrent({ id: room?.id, name: room?.name }));
    dispatch(floorsSlice.actions.resetCurrent({ id: floor?.id }));

    if (schedule?.startDate) {
      const value = formatInTimeZone(new Date(schedule.startDate), timeZone, "yyyy-MM-dd");

      dispatch(reservationsSlice.actions.setAvailabilityQuery({ startDate: value, endDate: value }));
    }
  }, [reservation]);

  const handleAllDaySwitchChange: AllDaySwitchProps["onChange"] = (checked) => {
    dispatch(reservationsSlice.actions.setCurrent({ isAllDay: checked || undefined }));
  };

  return (
    <>
      <Container>
        <PageHeader href="/" title={t`Edit meeting`} />
        <Box display="flex">
          <Box
            sx={({ breakpoints }) => ({
              flex: "1 0 65%",
              maxWidth: "65%",
              [breakpoints.up("xl")]: {
                flex: "1 0 70%",
                maxWidth: "70%",
              },
            })}
          >
            <Typography fontWeight="600" marginBottom={1}>{t`Select room`}</Typography>
            <Box alignItems="flex-end" display="flex" justifyContent="space-between" marginBottom={2}>
              <Tabs inline onChange={(_, tab) => setCurrentTab(tab)} value={currentTab}>
                <Tab label={t`List View`} />
                <Tab label={t`Calendar View`} />
              </Tabs>
              <EditReservationRouteFloorSelector defaultFloorId={reservation?.floor?.id} locationId={reservation?.floor?.location?.id} />
            </Box>
            {currentTab === 1 ? (
              <Box display="flex" justifyContent="space-between">
                <Typography color={theme.palette.grey[700]} fontSize={12} fontWeight={500} mb={1}>
                  {t`* All times shown are in`}
                  {" "}
                  <Tooltip disableInteractive title={windowsTimeZone ? t`Derived from "${windowsTimeZone}" time zone.` : undefined}>
                    <Box
                      bgcolor={theme.palette.grey[100]}
                      borderRadius={1}
                      color={theme.palette.grey[800]}
                      component="span"
                      display="inline-block"
                      paddingX={0.5}
                      paddingY={0.25}
                    >
                      {timeZone}
                    </Box>
                  </Tooltip>
                  {" "}
                  {t`time zone.`}
                </Typography>
                <AllDaySwitch
                  checked={!!currentReservation?.isAllDay}
                  endDate={safeParseISO(currentReservation?.endDate)}
                  onChange={handleAllDaySwitchChange}
                  startDate={safeParseISO(currentReservation?.startDate)}
                  unavailable={!allDayIsAvailable}
                />
              </Box>
            ) : undefined}
            <TabPanel index={0} value={currentTab}>
              {reservationIsLoading || reservationIsFetching || !reservation ? (
                <Skeleton
                  animation="wave"
                  height={128}
                  sx={({ palette }) => ({ borderRadius: 2, bgcolor: palette.grey[100] })}
                  variant="rectangular"
                />
              ) : (
                <EditReservationRouteListView reservation={reservation} />
              )}
            </TabPanel>
            <TabPanel index={1} value={currentTab}>
              {reservationIsLoading || reservationIsFetching || !reservation ? (
                <Skeleton
                  animation="wave"
                  height={128}
                  sx={({ palette }) => ({ borderRadius: 2, bgcolor: palette.grey[100] })}
                  variant="rectangular"
                />
              ) : (
                <EditReservationRouteCalendarView reservation={reservation} />
              )}
            </TabPanel>
          </Box>
          <Box
            sx={({ breakpoints }) => ({
              flex: "1 0 35%",
              maxWidth: "35%",
              paddingLeft: 2,
              [breakpoints.up("xl")]: {
                flex: "1 0 30%",
                maxWidth: "30%",
                paddingLeft: 3,
              },
            })}
          >
            <Typography fontWeight="600" marginBottom={1}>{t`Reservation details`}</Typography>
            {reservationIsLoading || reservationIsFetching || !reservation ? (
              <Box>
                <Box marginBottom={2} paddingBottom={2.5} paddingTop={2.5}>
                  <Skeleton height={32} sx={({ palette }) => ({ bgcolor: palette.grey[100], borderRadius: 2 })} variant="rectangular" />
                </Box>
                <Skeleton
                  height={32}
                  sx={({ palette }) => ({ bgcolor: palette.grey[100], borderRadius: 2, marginBottom: 2 })}
                  variant="rectangular"
                />
                <Skeleton
                  height={32}
                  sx={({ palette }) => ({ bgcolor: palette.grey[100], borderRadius: 2, marginBottom: 2 })}
                  variant="rectangular"
                />
                <Skeleton
                  height={32}
                  sx={({ palette }) => ({ bgcolor: palette.grey[100], borderRadius: 2, marginBottom: 2 })}
                  variant="rectangular"
                />
                <Skeleton height={68} sx={({ palette }) => ({ bgcolor: palette.grey[100], borderRadius: 2 })} variant="rectangular" />
              </Box>
            ) : (
              <EditReservationRouteDetails currentTab={currentTab} onUpdated={() => setHasUpdated(true)} reservation={reservation} />
            )}
          </Box>
        </Box>
      </Container>
      <Dialog open={hasUpdated}>
        <Box padding={2}>
          <Typography fontSize={18} fontWeight="600" marginBottom={1}>{t`Reservation was successfully updated!`}</Typography>
          <Typography marginBottom={2}>{t`You can find all reservations details at your home page.`}</Typography>
          <Box alignItems="center" display="flex" justifyContent="flex-end">
            <Button data-cid="make-more-changes-button" onClick={() => setHasUpdated(false)} sx={{ marginRight: 2 }} variant="text">{t`Make more changes`}</Button>
            <Link to="/">
              <Button variant="contained">{t`Go to home page`}</Button>
            </Link>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};